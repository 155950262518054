/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      notes
      locations {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      name
      description
      type
      relaynumber
      portnumber
      controllerid
      camera {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      blocks {
        nextToken
        startedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      actionCameraId
      __typename
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        relaynumber
        portnumber
        controllerid
        sensorid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        actionCameraId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncActions = /* GraphQL */ `
  query SyncActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        type
        relaynumber
        portnumber
        controllerid
        sensorid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        actionCameraId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const actionsBySensorid = /* GraphQL */ `
  query ActionsBySensorid(
    $sensorid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionsBySensorid(
      sensorid: $sensorid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        relaynumber
        portnumber
        controllerid
        sensorid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        actionCameraId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      message
      type
      code
      devicepin
      active
      arn
      thing
      accountid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      controllerid
      sensor {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      lastChangedAt
      location {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAlerts = /* GraphQL */ `
  query SyncAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsbyType = /* GraphQL */ `
  query AlertsbyType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsbyType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsByControlleridAndCreatedAt = /* GraphQL */ `
  query AlertsByControlleridAndCreatedAt(
    $controllerid: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByControlleridAndCreatedAt(
      controllerid: $controllerid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsBySensoridAndCreatedAt = /* GraphQL */ `
  query AlertsBySensoridAndCreatedAt(
    $sensorid: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsBySensoridAndCreatedAt(
      sensorid: $sensorid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsByLocationidAndCreatedAt = /* GraphQL */ `
  query AlertsByLocationidAndCreatedAt(
    $locationid: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByLocationidAndCreatedAt(
      locationid: $locationid
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      name
      description
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      actionid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        armtime
        disarmtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        actionid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBlocks = /* GraphQL */ `
  query SyncBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        armtime
        disarmtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        actionid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const blocksByActionid = /* GraphQL */ `
  query BlocksByActionid(
    $actionid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blocksByActionid(
      actionid: $actionid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        armtime
        disarmtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        actionid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCamera = /* GraphQL */ `
  query GetCamera($id: ID!) {
    getCamera(id: $id) {
      id
      name
      model
      active
      type
      ipaddress
      macaddress
      username
      password
      status
      lasterror
      relaynumber
      alertminute
      alerthour
      controllerid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      schedules {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cameraControllerId
      __typename
    }
  }
`;
export const listCameras = /* GraphQL */ `
  query ListCameras(
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCameras = /* GraphQL */ `
  query SyncCameras(
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCameras(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const camerasByLocationid = /* GraphQL */ `
  query CamerasByLocationid(
    $locationid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    camerasByLocationid(
      locationid: $locationid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getController = /* GraphQL */ `
  query GetController($id: ID!) {
    getController(id: $id) {
      id
      name
      description
      dhcp
      ipaddress
      netmask
      gateway
      dns
      armall
      arn
      deviceid
      debug
      alerts {
        nextToken
        startedAt
        __typename
      }
      sensors {
        nextToken
        startedAt
        __typename
      }
      accountid
      locationid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listControllers = /* GraphQL */ `
  query ListControllers(
    $filter: ModelControllerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listControllers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncControllers = /* GraphQL */ `
  query SyncControllers(
    $filter: ModelControllerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncControllers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const controllersByLocationid = /* GraphQL */ `
  query ControllersByLocationid(
    $locationid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelControllerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controllersByLocationid(
      locationid: $locationid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      controllerid
      sensorid
      locationid
      ping
      alert
      active
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        controllerid
        sensorid
        locationid
        ping
        alert
        active
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        controllerid
        sensorid
        locationid
        ping
        alert
        active
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      description
      accountid
      controllers {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      cameras {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const locationsByAccountid = /* GraphQL */ `
  query LocationsByAccountid(
    $accountid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByAccountid(
      accountid: $accountid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPing = /* GraphQL */ `
  query GetPing($id: ID!) {
    getPing(id: $id) {
      id
      controllerid
      locationid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPings = /* GraphQL */ `
  query ListPings(
    $filter: ModelPingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        controllerid
        locationid
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPings = /* GraphQL */ `
  query SyncPings(
    $filter: ModelPingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        controllerid
        locationid
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pingsByControllerid = /* GraphQL */ `
  query PingsByControllerid(
    $controllerid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pingsByControllerid(
      controllerid: $controllerid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        controllerid
        locationid
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pingsByLocationid = /* GraphQL */ `
  query PingsByLocationid(
    $locationid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pingsByLocationid(
      locationid: $locationid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        controllerid
        locationid
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      name
      description
      port
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      payload
      cameraid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        port
        armtime
        disarmtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        payload
        cameraid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSchedules = /* GraphQL */ `
  query SyncSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        port
        armtime
        disarmtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        payload
        cameraid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const schedulesByCameraid = /* GraphQL */ `
  query SchedulesByCameraid(
    $cameraid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByCameraid(
      cameraid: $cameraid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        port
        armtime
        disarmtime
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        payload
        cameraid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      name
      description
      alertinterval
      alerttime
      sensorpin
      relaytype
      tamperpin
      locationid
      lastcheck
      controllerid
      actions {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSensors = /* GraphQL */ `
  query SyncSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSensors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sensorsByControllerid = /* GraphQL */ `
  query SensorsByControllerid(
    $controllerid: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorsByControllerid(
      controllerid: $controllerid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUnregistered = /* GraphQL */ `
  query GetUnregistered($id: ID!) {
    getUnregistered(id: $id) {
      id
      name
      arn
      thing
      registered
      message
      locationid
      controllerid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUnregistereds = /* GraphQL */ `
  query ListUnregistereds(
    $filter: ModelUnregisteredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnregistereds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        arn
        thing
        registered
        message
        locationid
        controllerid
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUnregistereds = /* GraphQL */ `
  query SyncUnregistereds(
    $filter: ModelUnregisteredFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUnregistereds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        arn
        thing
        registered
        message
        locationid
        controllerid
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      permissions
      cognito
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        permissions
        cognito
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        email
        permissions
        cognito
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
