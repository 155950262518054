import React, { useState, useEffect } from 'react'
import API from '@aws-amplify/api'
import * as bandit from '../../graphql/bandit'
import * as mutations from '../../graphql/mutations'
import { Link } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import {
  MDBDatatable,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBTextArea,
  MDBIcon,
  MDBSwitch,
} from 'mdb-react-ui-kit'
import moment from 'moment'

var _ = require('lodash')

export default function Accounts() {
  const [basicData, setBasicData] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Date Created', field: 'created', sort: true },
      { label: 'Locations', field: 'locations', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })

  const [createModal, setCreateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const toggleCreate = () => setCreateModal(!createModal)
  const toggleDelete = () => setDeleteModal(!deleteModal)

  const [accountBuffer, setAccountBuffer] = useState([])

  const [formValue, setFormValue] = useState({ name: '', notes: '' })

  const onChange = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  }

  const switchIt = (e: any) => {
    console.log(e)
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const accountdata = await API.graphql({ query: bandit.listAccounts });
      let accounts = accountdata.data.listAccounts.items
      accounts = _.reject(accounts, ['_deleted', true])
      accounts = _.sortBy(accounts, ['createdAt'])
      setBasicData((prevState) => {
        return {
          ...prevState,
          rows: accounts.map((row) => {
            return {
              ...row,
              locations: (
                <React.Fragment>
                  {row.locations.items.map((location) => {
                    return (
                      <div key={location.id}>
                        <Link to={'/location/' + location.id}><p>{location.name}</p></Link>
                      </div>
                    )
                  })}
                </React.Fragment>
              ),
              created: (
                <React.Fragment>
                  {moment(row.createdAt).format('MMMM Do YYYY, h:mm a')}
                </React.Fragment>
              ),
              armed: (
                <React.Fragment>
                  <MDBSwitch name='armed' checked={row.armed} onChange={switchIt} label='' />
                </React.Fragment>
              ),
              edit: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2'>
                    <Link to={'/account/' + row.id}>
                      <MDBIcon icon='edit' />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => startDeleteAccount(row)} >
                    <MDBIcon icon='trash' />
                  </MDBBtn>
                </React.Fragment>
              ),
            };
          }),
        }
      })

    } catch (err) { console.log(err) }
  }

  async function newAccount() {
    try {
      await API.graphql({ query: mutations.createAccount, variables: { input: formValue } })
      setCreateModal(false)
      setFormValue({ name: '', armed: false })
      onLoad()
    } catch (err) { console.log(err) }
  }

  async function deleteAccount() {
    const data = {
      id: accountBuffer.id,
      _version: accountBuffer._version,
    }
    try {
      await API.graphql({ query: mutations.deleteAccount, variables: { input: data } })
      setDeleteModal(false)
      onLoad()
    } catch (err) { console.log(err) }
  }

  function startDeleteAccount(row) {
    setAccountBuffer(row)
    setDeleteModal(true)
  }

  return (
    <Container>
      <div className='d-flex flex-row justify-content-between w-100 mb-3 pb-3 border-bottom'>
        <h2>Accounts</h2>
        <MDBBtn onClick={toggleCreate}><MDBIcon fas icon='plus-square' /> &nbsp;Add Account</MDBBtn>
      </div>
      <div style={{ maxWidth: '100%' }}>
        <MDBDatatable data={basicData} entries={10} />
      </div>
      <MDBModal open={deleteModal} setOpen={setDeleteModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Are you sure you want to delete</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleDelete}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {accountBuffer.name}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleDelete}>Cancel</MDBBtn>
              <MDBBtn onClick={deleteAccount}>Delete Account</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={createModal} setOpen={setCreateModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>New Account</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleCreate}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput className='mb-3' value={formValue.name} name='name' onChange={onChange} id='name' required label='Name' />
              <MDBTextArea label='Notes' value={formValue.notes} name='notes' onChange={onChange} id='notes' rows={4} />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleCreate}>Close</MDBBtn>
              <MDBBtn onClick={newAccount}>Save changes</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </Container>
  );
}