import React, { useState, useEffect } from 'react'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { API, Auth } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'
import * as mutations from '../../graphql/mutations'
import { Link, useLocation } from 'react-router-dom'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import {
  MDBContainer,
  MDBInputGroup,
  MDBRadio,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBDatatable,
  MDBTextArea,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSwitch,
} from 'mdb-react-ui-kit'
import Select from 'react-select'
import { hourops, sensorpinops, relaytypeops, tamperpinops } from './Options'
import logo from '../../art/bandit.svg'
import moment from 'moment'
import useForm from '../Form/useForm'
import validate from '../Form/Validation'
import { nanoid } from 'nanoid'
import { SchedulerClient, DeleteScheduleCommand, CreateScheduleCommand } from '@aws-sdk/client-scheduler'

var _ = require('lodash')

export default function Controller() {
  const location = useLocation()
  let controllerid = location.pathname
  controllerid = controllerid.replace('/controller/', '')
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const config = {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    region: AWS_REGION,
  }
  const client = new SchedulerClient(config)
  const { values, picked, setPicked, changed, errors, handleChange, handleSubmit, switchDebug, switchDhcp, switchArmed, pickDevice, loadController } = useForm(
    updateController,
    validate
  )
  const [accloc, setAccloc] = useState()
  const [sensorData, setSensorData] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Description', field: 'description', sort: true },
      { label: 'Date Created', field: 'created', sort: true },
      { label: 'Last Tripped', field: 'lastcheck', sort: true },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })
  const [addsensModal, setAddsensModal] = useState(false)
  const toggleAddsensModal = () => setAddsensModal(!addsensModal)
  const [regModal, setRegModal] = useState(false)
  const [newSensor, setNewSensor] = useState({
    id: 's' + nanoid(),
    description: '',
    alerttime: 0,
    sensorpin: '',
    relaytype: '',
    tamperpin: '',
    controllerid: controllerid,
  })
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })
  const [devices, setDevices] = useState()
  const [credentials, setCredentials] = useState([])

  async function onLoad() {
    try {
      const creds = await Auth.currentUserCredentials()
      setCredentials(creds)
    } catch (error) {
      console.error(error)
    }
    try {
      const cntrldata = await API.graphql({ query: bandit.getController, variables: { id: controllerid } })
      let sensorlist = cntrldata.data.getController.sensors.items
      const locdata = await API.graphql({ query: bandit.getLocation, variables: { id: cntrldata.data.getController.locationid } })
      setAccloc(locdata.data.getLocation.name)
      const unreg = await API.graphql({ query: bandit.listUnregistereds })
      let devs = unreg.data.listUnregistereds.items
      devs = _.reject(devs, ['registered', true])
      var keys = { thing: 'label', arn: 'value' }
      var result = devs.map(function (o) {
        return _.mapKeys(o, function (v, k) {
          return k in keys ? keys[k] : k
        })
      })
      setDevices(result)
      setSensorData((prevState) => {
        return {
          ...prevState,
          rows: sensorlist.map((row) => {
            return {
              ...row,
              created: <React.Fragment>{moment(row.createdAt).format('MMMM Do YYYY, h:mm a')}</React.Fragment>,
              lastcheck: <React.Fragment>{moment(row.lastcheck).format('MMMM Do YYYY, h:mm a')}</React.Fragment>,
              edit: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2'>
                    <Link to={'/sensor/' + row.id}>
                      <MDBIcon icon='edit' />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => trashSensor(row)}>
                    <MDBIcon icon='trash' />
                  </MDBBtn>
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function updateController() {
    //console.log(values)
    try {
      let cleaned = _.omit(values, ['deviceversion', 'actions', 'device', 'sensors', 'createdAt', 'updatedAt', '_deleted', '_lastChangedAt'])
      await API.graphql({ query: mutations.updateController, id: controllerid, variables: { input: cleaned } })
      setToastContent({ ...toastContent, action: 'Controller Updated' })
      setToast(true)
      loadController()
    } catch (err) {
      console.log('Error updating Controller Table:', err)
      setToastContent({ ...toastContent, action: 'ERROR' })
      setToast(true)
    }
  }

  async function updateUnregistered() {
    try {
      const data = {
        id: values.deviceid, 
        _version: values.deviceversion,
        registered: true,
      }
      const dereg = await API.graphql({ query: mutations.updateUnregistered, variables: { input: data } })
      //console.log(dereg)
      setPicked(false)
    } catch (err) {
      console.log('Error updating Unregistered Table:', err)
    }
  }

  function validateSensor(e) {
    e.preventDefault()
    addSensor()
  }

  async function addSensor() {
    const ebs = {
      Name: newSensor.id, // required
      ScheduleExpression: 'rate(15 minutes)',
      Description: 'ON : ' + newSensor.name + ' - ' + newSensor.description,
      ScheduleExpressionTimezone: 'America/Los_Angeles',
      State: 'ENABLED',
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:sensoralert-${USER_BRANCH}`,
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`,
        RetryPolicy: {
          MaximumEventAgeInSeconds: Number(600),
          MaximumRetryAttempts: Number(3),
        },
        Input: `{ \"interval\":\"${newSensor.alertinterval}\",\"increment\": \"${newSensor.alerttime}\",\"controllerid\": \"${controllerid}\",\"sensorname\": \"${newSensor.name}\",\"sensorid\": \"${newSensor.id}\",\"schedule\": \"${newSensor.id}\",\"locationid\": \"${values.locationid}\" }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF',
      },
    }
    try {
      await API.graphql({ query: mutations.createSensor, variables: { input: newSensor } })
    } catch (err) {
      console.log(`Create Sensor Failed : `, err)
    }
    try {
      const command = new CreateScheduleCommand(ebs)
      const response = await client.send(command)
    } catch (err) {
      console.log(`Create Sensor Schedule Failed : `, err)
    }
    setToastContent({ header: '', name: newSensor.name, action: 'created', content: newSensor.name })
    setToast(true)
    setAddsensModal(false)
    setNewSensor({
      id: 's' + nanoid(),
      name: '',
      description: '',
      alerttime: 0,
      sensorpin: 'None',
      relaytype: 'NO',
      tamperpin: 'None',
      controllerid: controllerid,
    })
    onLoad()
  }

  async function trashSensor(action) {
    const data = {
      id: action.id,
      _version: action._version,
    }
    try {
      await API.graphql({ query: mutations.deleteSensor, variables: { input: data } })
    } catch (err) {
      console.log(err)
    }
    try {
      const trash = new DeleteScheduleCommand({ Name: action.id })
      const response = await client.send(trash)
    } catch (err) {
      console.log(err)
    }
    setToastContent({ name: action.name, action: 'has been deleted.' })
    setToast(true)
    onLoad()
  }

  async function deviceRegister() {
    const payload = {
      eventtype: 'new_device_registration',
      arn: values.arn,
      controllerid: values.id,
    }
    try {
      const lambdaClient = new LambdaClient({
        region: AWS_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })
      const command = new InvokeCommand({
        FunctionName:`banditmqtt-${USER_BRANCH}`,
        Payload: JSON.stringify(payload),
      })
      const response = await lambdaClient.send(command)
      console.log('Response from Lambda:', response)
    } catch (error) {
      console.error('Error invoking Lambda:', error)
    }
  }

  function saveChanges() {
    updateController()
    { picked && 
      updateUnregistered()
      deviceRegister()
    }
  }

  function setSensor(k, v) {
    setNewSensor({ ...newSensor, [k]: v })
  }

  function listDevices(e) {
    if (!devices == '') {
      return (
        <MDBInputGroup textBefore='Device' className='mb-3'>
          <Select className='react-select reg-cntrl' options={devices} placeholder='REGISTER CONTROLLER' onChange={(e) => pickDevice('arn', e)} />
        </MDBInputGroup>
      )
    }
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <MDBContainer className='content'>
        <MDBRow>
          <MDBCol md='10'>
            <h2>
              <Link to={'/location/' + values.locationid}>{accloc}</Link> / {values.name || ''}
            </h2>
          </MDBCol>
          <MDBCol md='2' className='d-flex flex-row-reverse mb-2'></MDBCol>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer className='content'>
        <MDBRow>
          <form onSubmit={handleSubmit} noValidate>
            <MDBRow className='my-3 form-selects'>
              <MDBCol md='6'>
                <h4>Controller : </h4>
                <hr />
                <div className='input-group mb-3'>
                  <span className='input-group-text'>Name</span>
                  <input
                    autoComplete='off'
                    className={`form-control ${errors.name && 'text-danger'}`}
                    type='text'
                    name='name'
                    onChange={handleChange}
                    value={values.name || ''}
                    required
                  />
                </div>
                {errors.name && <p className='alert alert-danger'>{errors.name}</p>}
                <div className='input-group mb-3'>
                  <span className='input-group-text'>Description</span>
                  <input
                    autoComplete='off'
                    className={`form-control ${errors.description && 'text-danger'}`}
                    type='text'
                    name='description'
                    onChange={handleChange}
                    value={values.description || ''}
                    required
                  />
                </div>
                {errors.description && <p className='alert alert-danger'>{errors.description}</p>}
                {values.arn ? (
                  <div className='input-group mb-3'>
                    <span className='input-group-text'>Device</span>
                    <input
                      autoComplete='off'
                      className={`form-control ${errors.arn && 'text-danger'}`}
                      type='text'
                      name='arn'
                      onChange={handleChange}
                      value={values.arn || ''}
                      required
                    />
                  </div>
                ) : (
                  listDevices()
                )}
                {errors.arn && <p className='alert alert-danger'>{errors.arn}</p>}
              </MDBCol>
              <MDBCol md='6'>
                <MDBRow>
                  <MDBCol md='6'>
                    <MDBSwitch className='mb-3' name='armall' label='All Armed?' checked={values.armall || ''} onChange={switchArmed} />
                  </MDBCol>
                  <MDBCol md='6'>
                    <MDBSwitch className='mb-3' name='debug' label='Debug Active' checked={values.debug || ''} onChange={switchDebug} />
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBSwitch className='mb-3' name='dhcp' checked={values.dhcp || ''} onChange={switchDhcp} label='Enable DHCP' />
                {values.dhcp != true && (
                  <React.Fragment>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>IP Address</span>
                      <input
                        autoComplete='off'
                        className={`form-control ${errors.ipaddress && 'text-danger'}`}
                        type='text'
                        name='ipaddress'
                        onChange={handleChange}
                        value={values.ipaddress || ''}
                        required
                      />
                    </div>
                    {errors.ipaddress && <p className='alert alert-danger'>{errors.ipaddress}</p>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Subnet Mask</span>
                      <input
                        autoComplete='off'
                        className={`form-control ${errors.netmask && 'text-danger'}`}
                        type='text'
                        name='netmask'
                        onChange={handleChange}
                        value={values.netmask || ''}
                        required
                      />
                    </div>
                    {errors.netmask && <p className='alert alert-danger'>{errors.netmask}</p>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>Gateway</span>
                      <input
                        autoComplete='off'
                        className={`form-control ${errors.gateway && 'text-danger'}`}
                        type='text'
                        name='gateway'
                        onChange={handleChange}
                        value={values.gateway || ''}
                        required
                      />
                    </div>
                    {errors.gateway && <p className='alert alert-danger'>{errors.gateway}</p>}
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>DNS Server</span>
                      <input
                        autoComplete='off'
                        className={`form-control ${errors.dns && 'text-danger'}`}
                        type='text'
                        name='dns'
                        onChange={handleChange}
                        value={values.dns || ''}
                        required
                      />
                    </div>
                    {errors.dns && <p className='alert alert-danger'>{errors.dns}</p>}
                  </React.Fragment>
                )}
              </MDBCol>
            </MDBRow>
          </form>
          <MDBRow className='my-3'>
            <MDBCol md='10'></MDBCol>
            <MDBCol md='2' className='d-flex flex-row-reverse'>
              <MDBBtn type='submit' onClick={saveChanges} disabled={!changed}>
                Update Controller
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBRow>
        <hr />
      </MDBContainer>
      <MDBContainer>
        <div className='d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom'>
          <h4>Sensors</h4>
          <MDBBtn onClick={toggleAddsensModal}>
            <MDBIcon fas icon='plus-square' /> &nbsp;Add Sensor
          </MDBBtn>
        </div>
        <MDBDatatable data={sensorData} entries={10} />
      </MDBContainer>
      <MDBModal open={addsensModal} setOpen={setAddsensModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add a Sensor</MDBModalTitle>
            </MDBModalHeader>
            <form data-mdb-validate onSubmit={validateSensor}>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol md='6'>
                    <MDBInput
                      className='mb-3'
                      value={newSensor.name}
                      name='name'
                      onChange={(e) => setSensor('name', e.target.value)}
                      id='sensor-name'
                      required
                      label='Name'
                    />
                    <MDBTextArea
                      className='mb-3'
                      id='sensor-desc'
                      label='description'
                      value={newSensor.description || ''}
                      type='text'
                      onChange={(e) => setSensor('description', e.target.value)}
                    />
                    <label htmlFor='alerttime' className='form-label'>
                      Last Alert Interval
                    </label>
                    <MDBInputGroup className='border p-2 rounded-2'>
                      <MDBRadio
                        name='alerttime'
                        id='analog'
                        checked={newSensor.alerttime === 'hours'}
                        label='Hours'
                        inline
                        onChange={(e) => setSensor('alerttime', 'hours')}
                        required
                      />
                      <MDBRadio
                        name='alerttime'
                        id='digital'
                        checked={newSensor.alerttime === 'days'}
                        label='Days'
                        inline
                        onChange={(e) => setSensor('alerttime', 'days')}
                        className='mb-3'
                      />
                      {newSensor.alerttime === 'hours' && (
                        <MDBInputGroup textBefore='Hours' className='mb-3'>
                          <Select
                            required
                            className='react-select'
                            options={hourops}
                            placeholder={newSensor.alertinterval}
                            onChange={(e) => setSensor('alertinterval', e.value)}
                          />
                        </MDBInputGroup>
                      )}
                      {newSensor.alerttime === 'days' && (
                        <React.Fragment>
                          <MDBInputGroup textBefore='Days' className='mb-3'>
                            <MDBInput
                              id='days'
                              required
                              type='number'
                              placeholder={newSensor.alertinterval}
                              onChange={(e) => setSensor('alertinterval', e.target.value)}
                            />
                          </MDBInputGroup>
                        </React.Fragment>
                      )}
                    </MDBInputGroup>
                  </MDBCol>
                  <MDBCol md='6'>
                    <MDBInputGroup textBefore='Sensor Pin' className='mb-3'>
                      <Select
                        required
                        className='react-select'
                        options={sensorpinops}
                        placeholder={newSensor.sensorpin}
                        onChange={(e) => setSensor('sensorpin', e.value)}
                      />
                    </MDBInputGroup>
                    <MDBInputGroup textBefore='Sensor Relay Type' className='mb-3'>
                      <Select
                        required
                        className='react-select'
                        options={relaytypeops}
                        placeholder={newSensor.relaytype}
                        onChange={(e) => setSensor('relaytype', e.value)}
                      />
                    </MDBInputGroup>
                    <MDBInputGroup textBefore='Pi Tamper Pin' className='mb-3'>
                      <Select
                        required
                        className='react-select'
                        options={tamperpinops}
                        placeholder={newSensor.tamperpin}
                        onChange={(e) => setSensor('tamperpin', e.value)}
                      />
                    </MDBInputGroup>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn type='submit'>Add Sensor</MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={regModal} setOpen={setRegModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Register a Controller</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              {devices ? (
                devices.map(function (val) {
                  return <MDBRadio btn key={val.id} wrapperClass='device-selector' name='devices' id={val.arn} label={val.arn} onClick={pickDevice} />
                })
              ) : (
                <div>There are no unregistered controllers for this location</div>
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer position='middle-center'>
        <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img src={logo} className='me-2' alt='' width='32px' />
            <strong className='me-auto'>Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {toastContent.name} {toastContent.action}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </React.Fragment>
  )
}
