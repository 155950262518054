import React from 'react'
import useForm from './useForm'
import validate from './Validation'

export default function Form() {

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(login, validate)

  function login() {
    console.log('No errors, submit callback called!')
  }

  return (
    <div className="section is-fullheight">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-group mb-3">
                <span className="input-group-text">IP Address</span>
                <input autoComplete="off" className={`form-control ${errors.ipaddress && 'text-danger'}`} type="text" name="ipaddress" onChange={handleChange} value={values.ipaddress || ''} required />
              </div>
              {errors.ipaddress && (
                <p className="alert alert-danger">{errors.ipaddress}</p>
              )}
              <div className="input-group mb-3">
                <span className="input-group-text">Subnet Mask</span>
                <input autoComplete="off" className={`form-control ${errors.subnet && 'text-danger'}`} type="text" name="subnet" onChange={handleChange} value={values.subnet || ''} required />
              </div>
              {errors.subnet && (
                <p className="alert alert-danger">{errors.subnet}</p>
              )}
              <div className="input-group mb-3">
                <span className="input-group-text">Gateway</span>
                <input autoComplete="off" className={`form-control ${errors.gateway && 'text-danger'}`} type="text" name="gateway" onChange={handleChange} value={values.gateway || ''} required />
              </div>
              {errors.gateway && (
                <p className="alert alert-danger">{errors.gateway}</p>
              )}
              <div className="input-group mb-3">
                <span className="input-group-text">DNS Server</span>
                <input autoComplete="off" className={`form-control ${errors.dns && 'text-danger'}`} type="text" name="dns" onChange={handleChange} value={values.dns || ''} required />
              </div>
              {errors.dns && (
                <p className="alert alert-danger">{errors.dns}</p>
              )}
              <button type="submit" className="btn btn-secondary">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
