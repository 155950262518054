import React, { useState, useEffect } from 'react'
import Amplify, { Auth, API } from 'aws-amplify'
import awsconfig from '../../aws-exports'
import Container from 'react-bootstrap/Container'
import { MDBDatatable, MDBBtn, MDBIcon } from 'mdb-react-ui-kit'
import { Link, useLocation } from 'react-router-dom'
import { ListUsersCommand, CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider'

function Users(props) {
  const [users, setUsers] = useState({
    columns: [
      { label: 'Username', field: 'Username', sort: true },
      { label: 'Email', field: 'email', sort: false },
      { label: 'ID', field: 'id', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
    ],
    rows: [],
  })
  const config = {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    region: 'us-west-2',
  }
  const client = new CognitoIdentityProviderClient(config)

  async function listUsers(limit) {
    try {
      const command = new ListUsersCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
      })
      const response = await client.send(command)
      let wtf = response.Users
      setUsers((prevState) => {
        return {
          ...prevState,
          rows: wtf.map((row) => {
            return {
              ...row,
              edit: (
                <React.Fragment>
                  <React.Fragment>
                    <MDBBtn size='sm' floating className='message-btn ms-2'>
                      <Link to={'/user/' + row.Username}>
                        <MDBIcon icon='edit' />
                      </Link>
                    </MDBBtn>
                  </React.Fragment>
                </React.Fragment>
              ),
              email: (
                <React.Fragment>
                  {row.Attributes.map((subitem, i) => {
                    if (subitem.Name == 'email') {
                      return <React.Fragment key={i}>{subitem.Value}</React.Fragment>
                    }
                  })}
                </React.Fragment>
              ),
              id: (
                <React.Fragment>
                  {row.Attributes.map((subitem, i) => {
                    if (subitem.Name == 'sub') {
                      return <React.Fragment key={i}>{subitem.Value}</React.Fragment>
                    }
                  })}
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    listUsers()
  }, [])

  return (
    <Container>
      <h1>Users</h1>
      <hr />
      <MDBDatatable data={users} />
    </Container>
  )
}

export default Users
