import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { API } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'

var _ = require('lodash')

const useForm = (callback, validate) => {
    const location = useLocation()
    let locationid = location.pathname
    locationid = locationid.replace('/location/', '')

    const [values, setValues] = useState({ 
        name: '',
        description: '',
        arn: '',
        armall: false, 
        ipaddress: '0.0.0.0',
        netmask: '0.0.0.0',
        gateway: '0.0.0.0',
        dns: '0.0.0.0',
        locationid: locationid
    })
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        (async () => {
            setValues(values => ({ ...values, locationid: locationid }))
        })();
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback()
        }
        return () => {

        }
    }, [errors])

    const handleSubmit = (event) => {
        //console.log(isSubmitting)
        //console.log(Object.keys(errors).length)
        //console.log(errors)
        if (event) event.preventDefault()
        setErrors(validate(values))
        setIsSubmitting(true)
    }

    const resetForm = (event) => {
        if (event) event.preventDefault()
        setValues({
            locationid: locationid,
            name: '',
            description: '',
            arn: '',
            armall: false,
            ipaddress: '0.0.0.0',
            netmask: '0.0.0.0',
            gateway: '0.0.0.0',
            dns: '0.0.0.0',
        })
    }

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }))
    }

    const switchDebug = (event) => {
        setValues({ ...values, debug: !values.debug })
    }

    const switchDhcp = (event) => {
        setValues({ ...values, dhcp: !values.dhcp })
    }

    const pickDevice = (k, e) => {
        setValues({ ...values, arn: e.value, deviceid: e.id })
      }

    return {
        resetForm,
        handleChange,
        handleSubmit,
        switchDebug, 
        switchDhcp,
        pickDevice,
        values,
        errors,
    }
}

export default useForm