import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { Link, useLocation } from 'react-router-dom'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import {
    MDBContainer,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBTextArea,
    MDBInputGroup,
    MDBSelect,
    MDBRadio,
    MDBCheckbox,
    MDBIcon,
    MDBDatatable,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBTimepicker,
    MDBSwitch
} from 'mdb-react-ui-kit'
import Select from 'react-select'
import logo from '../../art/bandit.svg'
import { portops } from './Options'

var _ = require('lodash')

export default function Action() {
    const location = useLocation()
    let actionid = location.pathname
    actionid = actionid.replace('/action/', '')

    const [theAction, setTheAction] = useState([{ name: '', description: '', }])
    const [newBlock, setNewBlock] = useState({
        name: '',
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        actionid: actionid
    })
    const [blockData, setBlockData] = useState({
        columns: [
            { label: 'Name', field: 'name', sort: true },
            { label: 'ARM', field: 'armtime', sort: false },
            { label: 'DISARM', field: 'disarmtime', sort: false },
            { label: 'Mon', field: 'monday', sort: false },
            { label: 'Tues', field: 'tuesday', sort: false },
            { label: 'Wed', field: 'wednesday', sort: false },
            { label: 'Thurs', field: 'thursday', sort: false },
            { label: 'Fri', field: 'friday', sort: false },
            { label: 'Sat', field: 'saturday', sort: false },
            { label: 'Sun', field: 'sunday', sort: false },
            { label: 'Edit', field: 'edit', sort: false },
            { label: 'Delete', field: 'delete', sort: false },
        ],
        rows: [],
    })
    const [armtime, setArmtime] = useState()
    const [disarmtime, setDisarmtime] = useState()
    const [controller, setController] = useState()
    const [camera, setCamera] = useState()
    const [cameras, setCameras] = useState([])
    const [cameraname, setCameraname] = useState('')
    const [toast, setToast] = useState(false)
    const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })
    const [blockModal, setBlockModal] = useState(false)
    const [blockSwitch, setBlockSwitch] = useState(false)

    const toggleBlock = () => setBlockModal(!blockModal)

    function setBlock(k, v) {
        setNewBlock({ ...newBlock, [k]: v })
    }

    function allDays() {
        setBlockSwitch(!blockSwitch)
        if (!blockSwitch) {
            setNewBlock({ ...newBlock, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true })
        } else {
            setNewBlock({ ...newBlock, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false })
        }
    }

    async function addBlock() {
        try {
            let blockhead = newBlock
                blockhead.armtime = armtime
                blockhead.disarmtime = disarmtime
                await API.graphql({ query: mutations.createBlock, variables: { input: blockhead } })
                setToastContent({ name: blockhead.name, action: 'has been added.' })
                setToast(true)
                setNewBlock({
                    ...newBlock, 
                    name: '',
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    armtime: '00:00:00',
                    disarmtime: '00:00:59',
                    actionid: actionid
                })
                setBlockModal(false)
                onLoad()
        } catch (err) { console.log(err) }
    }

    async function onLoad() {
        try {
            const cntrldata = await API.graphql({ query: bandit.getAction, variables: { id: actionid } })
            let cleaned = _.omit(cntrldata.data.getAction, ['createdAt', 'updatedAt', '_deleted', '_lastChangedAt'])
            if (cleaned.camera != null) {
                setCamera(cleaned.camera)
                setCameraname(cleaned.camera.name)
            }
            setTheAction(cleaned)
            let blocklist = cleaned.blocks.items
            const sendata = await API.graphql({ query: bandit.getSensor, variables: { id: cleaned.sensorid } })
            setController(sendata.data.getSensor.name)
            const cntdata = await API.graphql({ query: bandit.getController, variables: { id: sendata.data.getSensor.controllerid } })
            let filter = {
                locationid: {
                    eq: cntdata.data.getController.locationid
                }
            }
            let cams = await API.graphql({ query: queries.listCameras, variables: { filter: filter } })
            cams = cams.data.listCameras.items
            var keys = { name: 'label', id: 'value' }
            var result = cams.map(function (o) {
                return _.mapKeys(o, function (v, k) {
                    return k in keys ? keys[k] : k;
                });
            })
            setCameras(result)

            setBlockData((prevState) => {
                return {
                    ...prevState,
                    rows: blocklist.map((row) => {
                        return {
                            ...row,
                            armtime: (
                                <React.Fragment>
                                    {row.armtime}
                                </React.Fragment>
                            ),
                            disarmtime: (
                                <React.Fragment>
                                    {row.disarmtime}
                                </React.Fragment>
                            ),
                            monday: (
                                <React.Fragment>
                                    <MDBCheckbox name='monday' id='monday' defaultChecked={row.monday} aria-label="..." disabled />
                                </React.Fragment>
                            ),
                            tuesday: (
                                <React.Fragment>
                                    <MDBCheckbox name='tuesday' id='tuesday' defaultChecked={row.tuesday} aria-label='...' disabled />
                                </React.Fragment>
                            ),
                            wednesday: (
                                <React.Fragment>
                                    <MDBCheckbox name='wednesday' id='wednesday' defaultChecked={row.wednesday} aria-label='...' disabled />
                                </React.Fragment>
                            ),
                            thursday: (
                                <React.Fragment>
                                    <MDBCheckbox name='thursday' id='thursday' defaultChecked={row.thursday} aria-label='...' disabled />
                                </React.Fragment>
                            ),
                            friday: (
                                <React.Fragment>
                                    <MDBCheckbox name='friday' id='friday' defaultChecked={row.friday} aria-label='...' disabled />
                                </React.Fragment>
                            ),
                            saturday: (
                                <React.Fragment>
                                    <MDBCheckbox name='saturday' id='saturday' defaultChecked={row.saturday} aria-label='...' disabled />
                                </React.Fragment>
                            ),
                            sunday: (
                                <React.Fragment>
                                    <MDBCheckbox name='sunday' id='sunday' defaultChecked={row.sunday} aria-label='...' disabled />
                                </React.Fragment>
                            ),
                            edit: (
                                <React.Fragment>
                                    <MDBBtn size='sm' floating className='message-btn ms-2' >
                                        <Link to={'/block/' + row.id}>
                                            <MDBIcon icon='edit' />
                                        </Link>
                                    </MDBBtn>
                                </React.Fragment>
                            ),
                            delete: (
                                <React.Fragment>
                                    <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => trashBlock(row)} >
                                        <MDBIcon icon='trash' />
                                    </MDBBtn>
                                </React.Fragment>
                            ),
                        }
                    }),
                }
            })
        } catch (err) { console.log(err) }
    }

    async function updateAction() {
        try {
            let cleaned = _.omit(theAction, ['camera','blocks', 'createdAt', 'updatedAt', '_deleted', '_lastChangedAt'])
            await API.graphql({ query: mutations.updateAction, variables: { input: cleaned } })
            setToastContent({ ...toastContent, action: 'UPDATED' })
            setToast(true)
        } catch (err) {
            console.log(err)
            setToastContent({ ...toastContent, action: 'ERROR' })
            setToast(true)
        }
    }

    function setAction(key, value) {
        setTheAction({ ...theAction, [key]: value })
    }

    function chooseCamera(k, e) {
        if (e) {
            //console.log(e)
            //console.log(camera)
            setCamera({ ...camera, controllerid: theAction.controllerid})
            setTheAction({ ...theAction, [k]: e.value })
        }
    }

    function handleAction(k, e) {
        if (e) {
            //console.log(e)
            setTheAction({ ...theAction, [k]: e.value })
        }
    }

    async function trashBlock(block) {
        const data = {
            id: block.id,
            _version: block._version,
        }
        try {
            await API.graphql({ query: mutations.deleteBlock, variables: { input: data } })
            setToastContent({ name: block.name, action: 'has been deleted.' })
            setToast(true)
            onLoad()
        } catch (err) { console.log(err) }
    }

    function setTheArmtime(v) {
        v = v + ':00'
        setArmtime(v)
        //console.log(v)
    }

    function setTheDisarmtime(v) {
        v = v + ':59'
        setDisarmtime(v)
        //console.log(v)
    }

    useEffect(() => {
        onLoad()
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <MDBContainer className='content'>
                <MDBRow>
                    <h2><Link to={'/sensor/' + theAction.sensorid}>{controller}</Link> / {theAction.name || ''} </h2>
                    <hr />
                </MDBRow>
            </MDBContainer>
            <MDBContainer className='content'>
                <MDBRow className='my-3 form-selects'>
                    <MDBCol md='6'>
                        <MDBInput className='mb-3' id='name' label='Name' value={theAction.name || ''} type='text' onChange={event => setAction('name', event.target.value)} />
                        <MDBTextArea className='mb-3' id='description' label='Description' value={theAction.description || ''} type='text' onChange={event => setAction('description', event.target.value)} />
                    </MDBCol>
                    <MDBCol md='6'>
                        <MDBRadio name='type' id='analog' checked={theAction.type === 'analog'} label='Analog' inline onChange={event => setAction('type', 'analog')} />
                        <MDBRadio name='type' id='digital' checked={theAction.type === 'digital'} label='Digital' inline onChange={event => setAction('type', 'digital')} className="mb-3" />
                        {theAction.type === 'analog' ?
                            <MDBInputGroup textBefore='PI Relay Num' className='mb-3'>
                                <MDBInput id='relaynumber' value={theAction.relaynumber || ''} type='text' onChange={event => setAction('relaynumber', event.target.value)} />
                            </MDBInputGroup>
                                    :
                            <React.Fragment>
                                <MDBInputGroup textBefore='Camera' className='mb-3'>
                                    <Select className="react-select" options={cameras} placeholder={cameraname} onChange={(e) => chooseCamera('actionCameraId', e)} />                      
                                </MDBInputGroup>
                                <MDBInputGroup textBefore='VIO Number' className='mb-3'>
                                    <Select className="react-select" options={portops} placeholder={theAction.portnumber} onChange={(e) => handleAction('portnumber', e)} />                      
                                </MDBInputGroup>
                            </React.Fragment>
                        }
                    </MDBCol>
                </MDBRow>
                <MDBRow className='my-3'>
                    <MDBCol md='10'>
                    </MDBCol>
                    <MDBCol md='2' className="d-flex flex-row-reverse">
                        <MDBBtn onClick={updateAction}>Update Action</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <hr />
            </MDBContainer>
            <MDBContainer>
                <div className='d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom'>
                    <h4>Action Schedules</h4>
                    <MDBBtn onClick={toggleBlock}><MDBIcon fas icon='plus-square' /> &nbsp;Add Action Schedule</MDBBtn>
                </div>
                <MDBDatatable data={blockData} />
            </MDBContainer>
            <MDBModal open={blockModal} setOpen={setBlockModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Action Schedule to {theAction.name} </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow className='my-3 form-selects'>
                                <MDBCol md='12'>
                                    <MDBInputGroup textBefore='Name' className='mb-3'>
                                        <MDBInput id='name' value={newBlock.name || ''} type='text' onChange={event => setBlock('name', event.target.value)} />
                                    </MDBInputGroup>
                                </MDBCol>
                                <MDBCol md='12' className='mb-3'>
                                    <MDBTextArea label='Description' value={newBlock.description || ''} rows={3} onChange={event => setBlock('description', event.target.value)} id='description' />
                                </MDBCol>
                                <MDBCol md='6'>
                                    <MDBInputGroup textBefore='Arm Time' className='mb-3'>
                                        <MDBTimepicker inline format='24h' value={armtime} onChange={setTheArmtime} />
                                    </MDBInputGroup>
                                </MDBCol>
                                <MDBCol md='6'>
                                    <MDBInputGroup textBefore='Disarm Time' className='mb-4'>
                                        <MDBTimepicker inline format='24h' value={disarmtime} onChange={setTheDisarmtime} />
                                    </MDBInputGroup>
                                </MDBCol>
                                <MDBCol md='12' className='mb-3 d-flex justify-content-between'>
                                    <MDBSwitch id='blockDays' label='ALL' value={blockSwitch} onClick={allDays} />
                                    <MDBCheckbox name='monday' value='monday' id='MON' label="MON" aria-label='...' inline checked={newBlock.monday} onChange={event => setBlock('monday', event.target.checked)} />
                                    <MDBCheckbox name='tuesday' value='tuesday' id='TUE' label="TUE" aria-label='...' inline checked={newBlock.tuesday} onChange={event => setBlock('tuesday', event.target.checked)} />
                                    <MDBCheckbox name='wednesday' value='wednesday' id='WED' label="WED" aria-label='...' inline checked={newBlock.wednesday} onChange={event => setBlock('wednesday', event.target.checked)} />
                                    <MDBCheckbox name='thursday' value='thursday' id='THU' label="THU" aria-label='...' inline checked={newBlock.thursday} onChange={event => setBlock('thursday', event.target.checked)} />
                                    <MDBCheckbox name='friday' value='friday' id='FRI' label="FRI" aria-label='...' inline checked={newBlock.friday} onChange={event => setBlock('friday', event.target.checked)} />
                                    <MDBCheckbox name='saturday' value='saturday' id='SAT' label="SAT" aria-label='...' inline checked={newBlock.saturday} onChange={event => setBlock('saturday', event.target.checked)} />
                                    <MDBCheckbox name='sunday' value='sunday' id='SUN' label="SUN" aria-label='...' inline checked={newBlock.sunday} onChange={event => setBlock('sunday', event.target.checked)} />
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn onClick={addBlock}>Add Block</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <ToastContainer position='middle-center'>
                <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
                    <Toast.Header>
                        <img src={logo} className="me-2" alt="" width='32px' />
                        <strong className="me-auto">Bandit Control Panel</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{toastContent.name} {toastContent.action}</Toast.Body>
                </Toast>
            </ToastContainer>
        </React.Fragment>
    )
}
