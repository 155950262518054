import React, { useState, useEffect } from 'react'
import { API } from "aws-amplify"
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { useLocation } from 'react-router-dom'
import {
    MDBContainer,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCheckbox,
    MDBInputGroup,
    MDBTextArea,
    MDBTimepicker,
    MDBSwitch
} from 'mdb-react-ui-kit'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import logo from '../../art/bandit.svg'

var _ = require('lodash')

export default function Block() {
    const location = useLocation()
    let blockid = location.pathname
    blockid = blockid.replace('/block/', '')

    const [theBlock, setTheBlock] = useState([])
    const [sensor, setSensor] = useState()
    const [toast, setToast] = useState(false)
    const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })
    const [armtime, setArmtime] = useState()
    const [disarmtime, setDisarmtime] = useState()
    const [blockModal, setBlockModal] = useState(false)
    const toggleBlock = () => setBlockModal(!blockModal)
    const [blockSwitch, setBlockSwitch] = useState(false)

    function allDays() {
        setBlockSwitch(!blockSwitch)
        if (!blockSwitch) {
            setTheBlock({ ...theBlock, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true })
        } else {
            setTheBlock({ ...theBlock, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false })
        }
    }

    function setBlock(key, value) {
        setTheBlock({ ...theBlock, [key]: value })
    }

    async function onLoad() {
        try {
            const blockdata = await API.graphql({ query: queries.getBlock, variables: { id: blockid } })
            setTheBlock(blockdata.data.getBlock)
            let at = blockdata.data.getBlock.armtime.slice(0, -3)
            setArmtime(at)
            let dt = blockdata.data.getBlock.disarmtime.slice(0, -3)
            setDisarmtime(dt)
            const sensordata = await API.graphql({ query: queries.getAction, variables: { id: blockdata.data.getBlock.actionid } })
            setSensor(sensordata.data.getAction.name)
        } catch (err) { console.log(err) }
    }

    async function updateBlock() {
        try {
            let blockhead = theBlock
            blockhead.armtime = armtime + ':00'
            blockhead.disarmtime = disarmtime + ':59'
            let cleaned = _.omit(blockhead, ['cameras', 'createdAt', 'updatedAt', '_deleted', '_lastChangedAt', '__typename'])
            await API.graphql({ query: mutations.updateBlock, variables: { input: cleaned } })
            setToastContent({ header: 'Update', action: 'Updated', content: 'Block Updated' })
            setToast(true)
            onLoad()
        } catch (err) { 
            setToastContent({ header: 'Error', action: 'Error', content: 'Error updating block' })
            setToast(true)
            console.log(err)

        }
    }

    function handleSelect(k, e) {
        if (e) {
            let v = e.target.checked
            setTheBlock({ ...theBlock, [k]: v })
        }
    }

    useEffect(() => {
        onLoad()
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <MDBContainer className='content'>
                <MDBRow>
                    <h2><a href={'/action/' + theBlock.actionid}>{sensor}</a> / {theBlock.name || ''}</h2>
                    <hr />
                </MDBRow>
            </MDBContainer>
            <MDBContainer>
                <MDBRow className='my-3 form-selects'>
                    <MDBCol md='12'>
                        <MDBInputGroup textBefore='Name' className='mb-3'>
                            <MDBInput id='name' value={theBlock.name || ''} type='text' onChange={event => setBlock('name', event.target.value)} />
                        </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md='12' className='mb-3'>
                        <MDBTextArea label='Description' value={theBlock.description || ''} rows={3} onChange={event => setBlock('description', event.target.value)} id='description' />
                    </MDBCol>
                    <MDBCol md='6'>
                        <MDBInputGroup textBefore='Arm Time' className='mb-3'>
                            <MDBTimepicker inline format='24h' defaultValue={armtime} value={armtime} onChange={setArmtime} />
                        </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md='6'>
                        <MDBInputGroup textBefore='Disarm Time' className='mb-4'>
                            <MDBTimepicker inline format='24h' defaultValue={disarmtime} value={disarmtime} onChange={setDisarmtime} />
                        </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md='12' className='mb-3 d-flex justify-content-between'>
                        <MDBSwitch id='blockDays' label='ALL' value={blockSwitch} onClick={allDays} />
                        <MDBCheckbox name='monday' value='monday' id='MON' label="MON" aria-label='...' inline checked={theBlock.monday} onChange={event => setBlock('monday', event.target.checked)} />
                        <MDBCheckbox name='tuesday' value='tuesday' id='TUE' label="TUE" aria-label='...' inline checked={theBlock.tuesday} onChange={event => setBlock('tuesday', event.target.checked)} />
                        <MDBCheckbox name='wednesday' value='wednesday' id='WED' label="WED" aria-label='...' inline checked={theBlock.wednesday} onChange={event => setBlock('wednesday', event.target.checked)} />
                        <MDBCheckbox name='thursday' value='thursday' id='THU' label="THU" aria-label='...' inline checked={theBlock.thursday} onChange={event => setBlock('thursday', event.target.checked)} />
                        <MDBCheckbox name='friday' value='friday' id='FRI' label="FRI" aria-label='...' inline checked={theBlock.friday} onChange={event => setBlock('friday', event.target.checked)} />
                        <MDBCheckbox name='saturday' value='saturday' id='SAT' label="SAT" aria-label='...' inline checked={theBlock.saturday} onChange={event => setBlock('saturday', event.target.checked)} />
                        <MDBCheckbox name='sunday' value='sunday' id='SUN' label="SUN" aria-label='...' inline checked={theBlock.sunday} onChange={event => setBlock('sunday', event.target.checked)} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='my-3'>
                    <MDBCol md='10'>
                    </MDBCol>
                    <MDBCol md='2' className="d-flex flex-row-reverse">
                        <MDBBtn onClick={updateBlock}>Update Action Schedule</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <ToastContainer position='middle-center'>
                <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
                    <Toast.Header>
                        <img src={logo} className="me-2" alt="Bandit Logo" width='32px' />
                        <strong className="me-auto">Bandit Control Panel</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{toastContent.content}</Toast.Body>
                </Toast>
            </ToastContainer>
        </React.Fragment>
    )
}