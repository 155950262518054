import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { API, Auth } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'
import * as mutations from '../../graphql/mutations'

var _ = require('lodash')

const useForm = (callback, validate) => {
  const location = useLocation()
  let controllerid = location.pathname
  controllerid = controllerid.replace('/controller/', '')
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [picked, setPicked] = useState(false)
  const [changed, setChanged] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [credentials, setCredentials] = useState([])

  useEffect(() => {
    loadController()
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback()
    }
  }, [errors])

  async function loadController() {
    try {
      const creds = await Auth.currentUserCredentials()
      setCredentials(creds)
    } catch (error) {
      console.error(error)
    }
    try {
      const cntrldata = await API.graphql({ query: bandit.getController, variables: { id: controllerid } })
      let cntlr = _.omit(cntrldata.data.getController, ['createdAt', 'updatedAt', '_deleted', '_lastChangedAt'])
      setValues(cntlr)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    setErrors(validate(values))
    setIsSubmitting(true)
  }

  const handleChange = (event) => {
    event.persist()
    setValues((values) => ({ ...values, [event.target.name]: event.target.value }))
    setChanged(true)
  }

  const switchDebug = (event) => {
    setValues({ ...values, debug: !values.debug })
    setChanged(true)
  }

  const switchDhcp = (event) => {
    setValues({ ...values, dhcp: !values.dhcp })
    setChanged(true)
  }

  const pickDevice = (k, e) => {
    //console.log(e)
    setValues({ ...values, arn: e.value, deviceid: e.id, deviceversion: e._version })
    setChanged(true)
    setPicked(true)
  }

  async function switchArmed() {
    //update controller
    try {
      const res = await API.graphql({ query: mutations.updateController, variables: { input: { id: controllerid, armall: !values.armall, _version: values._version } } })
      console.log(`Updated the controller :`, res)
    } catch (err) {
      console.log('Error updating Controller Table:', err)
    }
    try {
      const lambdaClient = new LambdaClient({
        region: AWS_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })
      const payload = {
        eventtype: 'armall',
        armall: !values.armall,
        controllerid: controllerid,
      }
      const command = new InvokeCommand({
        FunctionName: `banditmqtt-${USER_BRANCH}`,
        Payload: JSON.stringify(payload),
      })
      const response = await lambdaClient.send(command)
      //console.log(response) // Update state with Lambda response
    } catch (error) {
      console.error('Error invoking Lambda:', error)
    }
    setValues({ ...values, armall: !values.armall })

    setChanged(true)
  }

  return {
    handleChange,
    handleSubmit,
    switchDebug,
    switchDhcp,
    switchArmed,
    pickDevice,
    loadController,
    setPicked,
    values,
    picked,
    changed,
    errors,
  }
}

export default useForm
