import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'
import { Link, useLocation } from 'react-router-dom'
import { MDBContainer, MDBRow, MDBCol, MDBBadge, MDBInput, MDBIcon } from 'mdb-react-ui-kit'
import moment from 'moment'

var _ = require('lodash')

export default function Alert() {
  const location = useLocation()
  let alertid = location.pathname
  alertid = alertid.replace('/alert/', '')

  const [theAlert, setTheAlert] = useState([])
  const [theLoc, setTheLoc] = useState([
    {
      name: '',
      id: '',
    },
  ])

  async function onLoad() {
    try {
      const blockdata = await API.graphql({ query: bandit.getAlert, variables: { id: alertid } })
      setTheAlert(blockdata.data.getAlert)
      const locdata = await API.graphql({ query: bandit.getLocation, variables: { id: blockdata.data.getAlert.locationid } })
      let cleaned = _.omit(locdata.data.getLocation, ['alerts', 'cameras', 'controllers', 'sensors', 'createdAt', 'updatedAt', '_deleted', '_lastChangedAt'])
      setTheLoc(cleaned)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <MDBContainer className='content'>
        <MDBRow>
          <h2>
            <a href={'/location/' + theAlert.locationid}>{theLoc.name}</a> / Alert
          </h2>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <MDBRow className='my-3 form-selects'>
          <h2>
            {theAlert.type == 'error' ? (
              <MDBBadge show className='ms-2' color='danger'>
                {theAlert.type}
              </MDBBadge>
            ) : (
              <MDBBadge show className='ms-2' color={theAlert.type}>
                {theAlert.type}
              </MDBBadge>
            )}
          </h2>
          <h2>
            <MDBBadge show className='ms-2' color='dark'>
              {theAlert.code}
            </MDBBadge>
          </h2>
        </MDBRow>
        <MDBInput className='mb-4' label='Message' id='name' value={theAlert.message || ''} type='text' disabled />
        <MDBInput className='mb-4' label='Timestamp' id='name' value={moment(theAlert._lastChangedAt).format('MMMM Do YYYY, h:mm:ss a')} type='text' disabled />
        <MDBRow className='my-3'>
          <MDBCol md='1'>
            <MDBIcon icon='map-marker-alt' /> Location:{' '}
          </MDBCol>
          <MDBCol md='11' className='d-flex flex-row'>
            <h4>
              <Link to={'/location/' + theAlert.locationid}>{theLoc.name}</Link>
            </h4>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  )
}
