import React, { useState, useEffect } from 'react'
import { SchedulerClient, DeleteScheduleCommand, CreateScheduleCommand, UpdateScheduleCommand } from '@aws-sdk/client-scheduler'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { API, Auth } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { Link, useLocation } from 'react-router-dom'
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBCheckbox,
  MDBSwitch,
  MDBBtn,
  MDBRadio,
  MDBIcon,
  MDBDatatable,
  MDBTimepicker,
  MDBInput,
  MDBInputGroup,
  MDBTextArea,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit'
import { MaterialReactTable } from 'material-react-table'
import { ToastContainer, Toast } from 'react-bootstrap'
import Select from 'react-select'
import { thourops, vportops, relaynumberops } from './Options'
import logo from '../../art/bandit.svg'
import { nanoid } from 'nanoid'

var _ = require('lodash')

export default function Camera() {
  const location = useLocation()
  let cameraid = location.pathname
  cameraid = cameraid.replace('/camera/', '')
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const [armtime, setArmtime] = useState('')
  const [disarmtime, setDisarmtime] = useState('')
  const [theCamera, setTheCamera] = useState([])
  const [toast, setToast] = useState(false)
  const [currentLocation, setCurrentLocation] = useState()
  const [currentController, setCurrentController] = useState()
  const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })
  const [weekdays, setWeekdays] = useState([])
  const [ebsonExpression, setEbsonExpression] = useState(['*', '*', '?', '*', '*', '*'])
  const [ebsoffExpression, setEbsoffExpression] = useState(['*', '*', '?', '*', '*', '*'])
  const [controlselect, setControlselect] = useState([])
  const [blockData, setBlockData] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'VIO', field: 'port', sort: false },
      { label: 'Enable', field: 'armtime', sort: false },
      { label: 'Disable', field: 'disarmtime', sort: false },
      { label: 'Mon', field: 'monday', sort: false },
      { label: 'Tues', field: 'tuesday', sort: false },
      { label: 'Wed', field: 'wednesday', sort: false },
      { label: 'Thurs', field: 'thursday', sort: false },
      { label: 'Fri', field: 'friday', sort: false },
      { label: 'Sat', field: 'saturday', sort: false },
      { label: 'Sun', field: 'sunday', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })
  const [blockModal, setBlockModal] = useState(false)
  const [newBlock, setNewBlock] = useState({
    id: 's' + nanoid(),
    name: '',
    description: '',
    port: 0,
    armtime: '',
    disarmtime: '',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    payload: '',
    cameraid: cameraid,
  })
  const [blockSwitch, setBlockSwitch] = useState(false)
  const toggleBlock = () => setBlockModal(!blockModal)
  const [credentials, setCredentials] = useState([])

  //FIGURE OUT HOW TO ADD Scheduler to Policy Boundry
  const config = {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    region: AWS_REGION,
  }
  const client = new SchedulerClient(config)

  async function onLoad() {
    try {
      const creds = await Auth.currentUserCredentials()
      setCredentials(creds)
    } catch (error) {
      console.error(error)
    }
    try {
      const cameradata = await API.graphql({
        query: bandit.getCamera,
        variables: {
          id: cameraid,
          filter: {
            _deleted: {
              ne: true,
            },
          },
        },
      })
      let cleaned = cameradata.data.getCamera
      let blocklist = []
      if (cleaned.schedules.items !== undefined) {
        blocklist = cleaned.schedules.items
      }
      cleaned = _.omit(cleaned, ['schedules', 'createdAt', 'updatedAt', '_lastChangedAt', '_deleted', '__typename'])
      console.log(cleaned)
      setTheCamera(cleaned)
      const locdata = await API.graphql({ query: bandit.getLocation, variables: { id: cleaned.locationid } })
      setCurrentLocation(locdata.data.getLocation.name)
      let controllerlist = locdata.data.getLocation.controllers.items
      controllerlist = _.reject(controllerlist, ['_deleted', true])
      const cntrlselect = _.map(controllerlist, (item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
      setControlselect(cntrlselect)
      setBlockData((prevState) => {
        return {
          ...prevState,
          rows: blocklist.map((row) => {
            return {
              ...row,
              port: <React.Fragment>{row.port}</React.Fragment>,
              armtime: <React.Fragment>{row.armtime}</React.Fragment>,
              disarmtime: <React.Fragment>{row.disarmtime}</React.Fragment>,
              monday: (
                <React.Fragment>
                  <MDBCheckbox name='monday' id='monday' defaultChecked={row.monday} aria-label='...' disabled />
                </React.Fragment>
              ),
              tuesday: (
                <React.Fragment>
                  <MDBCheckbox name='tuesday' id='tuesday' defaultChecked={row.tuesday} aria-label='...' disabled />
                </React.Fragment>
              ),
              wednesday: (
                <React.Fragment>
                  <MDBCheckbox name='wednesday' id='wednesday' defaultChecked={row.wednesday} aria-label='...' disabled />
                </React.Fragment>
              ),
              thursday: (
                <React.Fragment>
                  <MDBCheckbox name='thursday' id='thursday' defaultChecked={row.thursday} aria-label='...' disabled />
                </React.Fragment>
              ),
              friday: (
                <React.Fragment>
                  <MDBCheckbox name='friday' id='friday' defaultChecked={row.friday} aria-label='...' disabled />
                </React.Fragment>
              ),
              saturday: (
                <React.Fragment>
                  <MDBCheckbox name='saturday' id='saturday' defaultChecked={row.saturday} aria-label='...' disabled />
                </React.Fragment>
              ),
              sunday: (
                <React.Fragment>
                  <MDBCheckbox name='sunday' id='sunday' defaultChecked={row.sunday} aria-label='...' disabled />
                </React.Fragment>
              ),
              edit: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2'>
                    <Link to={'/schedule/' + row.id}>
                      <MDBIcon icon='edit' />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => trashBlock(row)}>
                    <MDBIcon icon='trash' />
                  </MDBBtn>
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function updateCamera() {
    const input = _.omit(theCamera, ['controller'])
    const ebs = {
      Name: theCamera.id, // required
      GroupName: theCamera.id,
      ScheduleExpression: 'rate(' + theCamera.alerthour + ' hours)',
      Description: 'Camera Health Check : ' + theCamera.name + ' - ' + theCamera.description,
      ScheduleExpressionTimezone: 'America/Los_Angeles',
      State: 'ENABLED',
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:banditmqtt-${USER_BRANCH}`, // required
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`, // required
        RetryPolicy: {
          // RetryPolicy
          MaximumEventAgeInSeconds: Number(600),
          MaximumRetryAttempts: Number(3),
        },
        Input: `{ \"eventtype\": \"camera_api_request\",\"action\": \"TEST\",\"controllerid\": \"${theCamera.cameraControllerId}\",\"cameraid\": \"${theCamera.id}\",\"port\": \"0\",\"schedule\": \"${theCamera.id}\" }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF', // required
      },
    }
    try {
      await API.graphql({ query: mutations.updateCamera, variables: { input: input } })
    } catch (err) {
      console.log(`ERROR updating Camera record (DYNAMO) :`,err)
    }
    try {
      const command = new UpdateScheduleCommand(ebs)
      const response = await client.send(command)
      console.log(response)
    } catch (err) {
      console.log(`ERROR updating Schedule record (EVENTBRIDGE) :`,err)
    }
    setToastContent({ ...toastContent, content: `${theCamera.name} has been updated` })
    setToast(true)
  }

  function setInput(key, value) {
    setTheCamera({ ...theCamera, [key]: value })
  }

  function handleSelect(k, e) {
    if (e) {
      let v = e.value
      setTheCamera({ ...theCamera, [k]: v })
    }
  }

  const switchIt = () => {
    setTheCamera({ ...theCamera, active: !theCamera.active })
  }

  async function invokeLambda() {
    try {
      const lambdaClient = new LambdaClient({
        region: AWS_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })
      const payload = {
        eventtype: 'notification_test',
        id: theCamera.id,
        controllerid: theCamera.cameraControllerId,
      }
      const command = new InvokeCommand({
        FunctionName: `banditmqtt-${USER_BRANCH}`,
        Payload: JSON.stringify(payload),
      })

      const response = await lambdaClient.send(command)
      console.log(response) // Update state with Lambda response
    } catch (error) {
      console.error('Error invoking Lambda:', error)
    }
  }

  function validateSchedule() {
    if (weekdays.length == 0) {
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected any days for the VIO schedule' })
      setToast(true)
      return
    }
    if (ebsonExpression[0] == 'NaN' || ebsonExpression[0] == '*' || ebsonExpression[1] == 'NaN' || ebsonExpression[1] == '*') {
      setToastContent({ ...toastContent, content: 'ERROR : You need to select an Arm Time for the VIO schedule' })
      setToast(true)
      return
    }
    if (ebsoffExpression[0] == 'NaN' || ebsoffExpression[0] == '*' || ebsoffExpression[1] == 'NaN' || ebsoffExpression[1] == '*') {
      setToastContent({ ...toastContent, content: 'ERROR : You need to select an Disarm Time for the VIO schedule' })
      setToast(true)
      return
    }
    if (newBlock.port == 0) {
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected a VIO for the camera' })
      setToast(true)
      return
    }
    if (newBlock.name == '') {
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected a name for the VIO schedule' })
      setToast(true)
      return
    }
    addBlock()
  }

  async function addBlock() {
    let blockhead = newBlock
    blockhead.armtime = armtime
    blockhead.disarmtime = disarmtime
    const ebson = {
      Name: blockhead.id + '1', // required
      GroupName: cameraid,
      ScheduleExpression: 'cron(' + ebsonExpression.join(' ') + ')',
      Description: 'ON : ' + blockhead.name + ' - ' + blockhead.description,
      ScheduleExpressionTimezone: 'America/Los_Angeles',
      State: 'ENABLED',
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:banditmqtt-${USER_BRANCH}`, // required
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`, // required
        RetryPolicy: {
          // RetryPolicy
          MaximumEventAgeInSeconds: Number(600),
          MaximumRetryAttempts: Number(3),
        },
        Input: `{ \"eventtype\": \"camera_api_request\",\"action\": \"ON\",\"controllerid\": \"${theCamera.cameraControllerId}\",\"cameraid\": \"${cameraid}\",\"port\": \"${blockhead.port}\",\"schedule\": \"${blockhead.id}\" }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF', // required
      },
    }
    const ebsoff = {
      Name: blockhead.id + '0', // required
      GroupName: cameraid,
      ScheduleExpression: 'cron(' + ebsoffExpression.join(' ') + ')',
      Description: 'OFF : ' + blockhead.name + ' - ' + blockhead.description,
      ScheduleExpressionTimezone: 'America/Los_Angeles',
      State: 'ENABLED',
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:banditmqtt-${USER_BRANCH}`, // required
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`, // required
        RetryPolicy: {
          // RetryPolicy
          MaximumEventAgeInSeconds: Number(600),
          MaximumRetryAttempts: Number(3),
        },
        Input: `{ \"eventtype\": \"camera_api_request\",\"action\": \"OFF\",\"controllerid\": \"${theCamera.cameraControllerId}\",\"cameraid\": \"${cameraid}\",\"port\": \"${blockhead.port}\",\"schedule\": \"${blockhead.id}\" }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF', // required
      },
    }
    blockhead.payload = `\"controllerid\": \"${theCamera.cameraControllerId}\",\"cameraid\": \"${cameraid}\",\"port\": \"${blockhead.port}\",\"schedule\": \"${blockhead.id}\"`
    try {
      await API.graphql({ query: mutations.createSchedule, variables: { input: blockhead } })
    } catch (err) {
      console.log(`ERROR creating schedule record (DYNAMO) :`,err)
    }
    try {
      const ebsonc = new CreateScheduleCommand(ebson)
      const ebsonr = await client.send(ebsonc)
      const ebsoffc = new CreateScheduleCommand(ebsoff)
      const ebsoffr = await client.send(ebsoffc)
    } catch (err) {
      console.log(`ERROR creating schedule (EVENTBRIDGE)`,err)
    }
    setToastContent({ content: `${blockhead.name} has been added.` })
    setToast(true)
    setBlockModal(false)
    setNewBlock({
      id: 's' + nanoid(),
      name: '',
      description: '',
      port: 0,
      armtime: '',
      disarmtime: '',
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      payload: '',
      cameraid: cameraid,
    })
    onLoad()
  }

  function allDays() {
    setBlockSwitch(!blockSwitch)
    if (!blockSwitch) {
      const updatedOn = [...ebsonExpression]
      updatedOn[4] = '1,2,3,4,5,6,7'
      setEbsonExpression(updatedOn)
      const updatedOff = [...ebsoffExpression]
      updatedOff[4] = '1,2,3,4,5,6,7'
      setEbsoffExpression(updatedOff)
      setWeekdays(['1', '2', '3', '4', '5', '6', '7'])
      setNewBlock({ ...newBlock, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true })
    } else {
      const updatedOn = [...ebsonExpression]
      updatedOn[4] = ''
      setEbsonExpression(updatedOn)
      const updatedOff = [...ebsoffExpression]
      updatedOff[4] = ''
      setEbsoffExpression(updatedOff)
      setWeekdays([])
      setNewBlock({ ...newBlock, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false })
    }
  }

  function setTheArmtime(v) {
    const [hour, minute] = v.split(':')
    const updatedCron = [...ebsonExpression]
    updatedCron[0] = String(Number(minute))
    updatedCron[1] = String(Number(hour))
    setEbsonExpression(updatedCron)
    v = v + ':00'
    setArmtime(v)
  }

  function setTheDisarmtime(v) {
    const [hour, minute] = v.split(':')
    const updatedCron = [...ebsoffExpression]
    updatedCron[0] = String(Number(minute))
    updatedCron[1] = String(Number(hour))
    setEbsoffExpression(updatedCron)
    v = v + ':59'
    setDisarmtime(v)
  }

  async function trashBlock(block) {
    const data = {
      id: block.id,
      _version: block._version,
    }
    try {
      await API.graphql({ query: mutations.deleteSchedule, variables: { input: data } })
    } catch (err) {
      console.log(err)
    }
    try {
      const deleteon = new DeleteScheduleCommand({ Name: block.id + '1', GroupName: cameraid })
      const deleteoff = new DeleteScheduleCommand({ Name: block.id + '0', GroupName: cameraid })
      await client.send(deleteon)
      await client.send(deleteoff)
    } catch (err) {
      console.log(err)
    }
    setToastContent({ content: `${block.name} has been deleted.` })
    setToast(true)
    onLoad()
  }

  function setBlock(k, v) {
    switch (k) {
      case 'monday': //
        if (v) {
          setWeekdays([...weekdays, '2'])
        } else {
          let newdays = weekdays.filter((t) => t !== '2')
          setWeekdays(newdays)
        }
        break
      case 'tuesday': //
        if (v) {
          setWeekdays([...weekdays, '3'])
        } else {
          const newdays = weekdays.filter((t) => t !== '3')
          setWeekdays(newdays)
        }
        break
      case 'wednesday': //
        if (v) {
          setWeekdays([...weekdays, '4'])
        } else {
          const newdays = weekdays.filter((t) => t !== '4')
          setWeekdays(newdays)
        }
        break
      case 'thursday': //
        if (v) {
          setWeekdays([...weekdays, '5'])
        } else {
          const newdays = weekdays.filter((t) => t !== '5')
          setWeekdays(newdays)
        }
        break
      case 'friday': //
        if (v) {
          setWeekdays([...weekdays, '6'])
        } else {
          const newdays = weekdays.filter((t) => t !== '6')
          setWeekdays(newdays)
        }
        break
      case 'saturday': //
        if (v) {
          setWeekdays([...weekdays, '7'])
        } else {
          const newdays = weekdays.filter((t) => t !== '7')
          setWeekdays(newdays)
        }
        break
      case 'sunday': //
        if (v) {
          setWeekdays([...weekdays, '1'])
        } else {
          const newdays = weekdays.filter((t) => t !== '1')
          setWeekdays(newdays)
        }
        break
      default:
        //console.log(k)
        break
    }
    setNewBlock({ ...newBlock, [k]: v })
  }

  useEffect(() => {
    const updatedOn = [...ebsonExpression]
    updatedOn[4] = String(weekdays)
    setEbsonExpression(updatedOn)
    const updatedOff = [...ebsoffExpression]
    updatedOff[4] = String(weekdays)
    setEbsoffExpression(updatedOff)
  }, [weekdays])

  useEffect(() => {
    onLoad()
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <MDBContainer className='content'>
        <MDBRow>
          <MDBCol md='10'>
            <h2>
              <Link to={'/location/' + theCamera.locationid}>{currentLocation}</Link> / {theCamera.name}
            </h2>
          </MDBCol>
          <MDBCol md='2' className='d-flex flex-row-reverse mb-2'>
            <MDBBtn type='submit' color='danger' onClick={invokeLambda}>
              Send Test Notification
            </MDBBtn>
          </MDBCol>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer className='content'>
        <MDBRow>
          <form>
            <MDBRow className='form-selects my-3'>
              <MDBCol size='6'>
                <MDBInput className='mb-3' label='Camera Name' id='name' value={theCamera.name || ''} type='text' onChange={(event) => setInput('name', event.target.value)} />
                <MDBInputGroup textBefore='Model' className='mb-3'>
                  <MDBInput id='model' value={theCamera.model || ''} type='text' onChange={(event) => setInput('model', event.target.value)} />
                </MDBInputGroup>
                <MDBInputGroup textBefore='MAC Address' className='mb-3'>
                  <MDBInput id='model' value={theCamera.macaddress || ''} type='text' onChange={(event) => setInput('macaddress', event.target.value)} />
                </MDBInputGroup>
                <MDBRadio name='type' id='analog' checked={theCamera.type === 'analog'} label='Analog' inline onChange={(event) => setInput('type', 'analog')} />
                <MDBRadio name='type' id='digital' checked={theCamera.type === 'digital'} label='Digital' inline onChange={(event) => setInput('type', 'digital')} className='mb-3' />
                {theCamera.type === 'analog' && (
                  <MDBInputGroup textBefore={'Relay Number'} className='mb-3'>
                    <Select className='react-select' options={relaynumberops} placeholder={theCamera.relaynumber} onChange={(e) => handleSelect('relaynumber', e)} />
                  </MDBInputGroup>
                )}
                {theCamera.type === 'digital' && (
                  <React.Fragment>
                    <MDBInputGroup textBefore='IP Address' className='mb-3'>
                      <MDBInput id='ipaddress' value={theCamera.ipaddress || ''} type='text' onChange={(event) => setInput('ipaddress', event.target.value)} />
                    </MDBInputGroup>
                    <MDBInputGroup textBefore='Username' className='mb-3'>
                      <MDBInput id='username' value={theCamera.username || ''} type='text' onChange={(event) => setInput('username', event.target.value)} />
                    </MDBInputGroup>
                    <MDBInputGroup textBefore='Password' className='mb-3'>
                      <MDBInput id='password' value={theCamera.password || ''} type='password' onChange={(event) => setInput('password', event.target.value)} />
                    </MDBInputGroup>
                  </React.Fragment>
                )}
              </MDBCol>
              <MDBCol size='6'>
                <h5>Camera Health Check</h5>
                <MDBInputGroup textBefore={'Test Interval'} className='mb-3'>
                  <Select className='react-select' options={thourops} placeholder={theCamera.alerthour} onChange={(e) => handleSelect('alerthour', e)} />
                </MDBInputGroup>
                <h5>Connected Controller</h5>
                <MDBInputGroup textBefore={'Controller'} className='mb-3'>
                  <Select className='react-select' placeholder={theCamera.controller?.name || 'No Controller'} options={controlselect} onChange={(e) => handleSelect('cameraControllerId', e)} />
                </MDBInputGroup>
                <MDBInputGroup className='my-3'>
                  <MDBSwitch name='active' checked={theCamera.active || ''} onChange={(event) => switchIt()} label='Active' />
                </MDBInputGroup>
              </MDBCol>
            </MDBRow>
          </form>
          <MDBRow className='my-3'>
            <MDBCol size='10'></MDBCol>
            <MDBCol size='2' className='d-flex flex-row-reverse'>
              <MDBBtn onClick={updateCamera}>Update Camera</MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBRow>
      </MDBContainer>
      {theCamera.type === 'digital' && (
        <MDBContainer>
          <div className='d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom'>
            <h4>VIO Schedules</h4>
            <MDBBtn onClick={toggleBlock}>
              <MDBIcon fas icon='plus-square' /> &nbsp;Add VIO Schedule
            </MDBBtn>
          </div>
          <MDBDatatable data={blockData} />
        </MDBContainer>
      )}
      <ToastContainer position='middle-center'>
        <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img src={logo} className='me-2' alt='Bandit Logo' width='32px' />
            <strong className='me-auto'>Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>{toastContent.content}</Toast.Body>
        </Toast>
      </ToastContainer>
      <MDBModal open={blockModal} setOpen={setBlockModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Create VIO Schedule and add to {theCamera.name} </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className='my-3 form-selects'>
                <MDBCol md='6'>
                  <MDBInputGroup textBefore='Name' className='mb-3'>
                    <MDBInput value={newBlock.name || ''} type='text' onChange={(event) => setBlock('name', event.target.value)} />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol md='6'>
                  <MDBInputGroup textBefore={'VIO'} className='mb-3'>
                    <Select className='react-select' options={vportops} placeholder={newBlock.port || ''} onChange={(e) => setBlock('port', e.value)} />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol md='12' className='mb-3'>
                  <MDBTextArea label='Description' value={newBlock.description || ''} rows={3} onChange={(event) => setBlock('description', event.target.value)} />
                </MDBCol>
                <MDBCol md='6'>
                  <MDBInputGroup textBefore='Arm Time' className='mb-3'>
                    <MDBTimepicker justInput format='24h' value={armtime} onChange={setTheArmtime} />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol md='6'>
                  <MDBInputGroup textBefore='Disarm Time' className='mb-4'>
                    <MDBTimepicker justInput format='24h' value={disarmtime} onChange={setTheDisarmtime} />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol md='12' className='mb-3 d-flex justify-content-between'>
                  <MDBSwitch id='blockDays' label='ALL' value={blockSwitch} onClick={allDays} />
                  <MDBCheckbox name='monday' value='monday' id='MON' label='MON' aria-label='...' inline checked={newBlock.monday} onChange={(event) => setBlock('monday', event.target.checked)} />
                  <MDBCheckbox name='tuesday' value='tuesday' id='TUE' label='TUE' aria-label='...' inline checked={newBlock.tuesday} onChange={(event) => setBlock('tuesday', event.target.checked)} />
                  <MDBCheckbox name='wednesday' value='wednesday' id='WED' label='WED' aria-label='...' inline checked={newBlock.wednesday} onChange={(event) => setBlock('wednesday', event.target.checked)} />
                  <MDBCheckbox name='thursday' value='thursday' id='THU' label='THU' aria-label='...' inline checked={newBlock.thursday} onChange={(event) => setBlock('thursday', event.target.checked)} />
                  <MDBCheckbox name='friday' value='friday' id='FRI' label='FRI' aria-label='...' inline checked={newBlock.friday} onChange={(event) => setBlock('friday', event.target.checked)} />
                  <MDBCheckbox name='saturday' value='saturday' id='SAT' label='SAT' aria-label='...' inline checked={newBlock.saturday} onChange={(event) => setBlock('saturday', event.target.checked)} />
                  <MDBCheckbox name='sunday' value='sunday' id='SUN' label='SUN' aria-label='...' inline checked={newBlock.sunday} onChange={(event) => setBlock('sunday', event.target.checked)} />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={validateSchedule}>
                <MDBIcon fas icon='plus-square' /> &nbsp;Create VIO Schedule
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  )
}
