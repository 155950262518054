/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      notes
      locations {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      notes
      locations {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      notes
      locations {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      name
      description
      type
      relaynumber
      portnumber
      controllerid
      camera {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      blocks {
        nextToken
        startedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      actionCameraId
      __typename
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      name
      description
      type
      relaynumber
      portnumber
      controllerid
      camera {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      blocks {
        nextToken
        startedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      actionCameraId
      __typename
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      name
      description
      type
      relaynumber
      portnumber
      controllerid
      camera {
        id
        name
        model
        active
        type
        ipaddress
        macaddress
        username
        password
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        controllerid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        cameraControllerId
        __typename
      }
      blocks {
        nextToken
        startedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      actionCameraId
      __typename
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      message
      type
      code
      devicepin
      active
      arn
      thing
      accountid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      controllerid
      sensor {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      lastChangedAt
      location {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      message
      type
      code
      devicepin
      active
      arn
      thing
      accountid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      controllerid
      sensor {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      lastChangedAt
      location {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      message
      type
      code
      devicepin
      active
      arn
      thing
      accountid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      controllerid
      sensor {
        id
        name
        description
        alertinterval
        alerttime
        sensorpin
        relaytype
        tamperpin
        locationid
        lastcheck
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sensorid
      createdAt
      updatedAt
      lastChangedAt
      location {
        id
        name
        description
        accountid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      name
      description
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      actionid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      name
      description
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      actionid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      name
      description
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      actionid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCamera = /* GraphQL */ `
  mutation CreateCamera(
    $input: CreateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    createCamera(input: $input, condition: $condition) {
      id
      name
      model
      active
      type
      ipaddress
      macaddress
      username
      password
      status
      lasterror
      relaynumber
      alertminute
      alerthour
      controllerid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      schedules {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cameraControllerId
      __typename
    }
  }
`;
export const updateCamera = /* GraphQL */ `
  mutation UpdateCamera(
    $input: UpdateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    updateCamera(input: $input, condition: $condition) {
      id
      name
      model
      active
      type
      ipaddress
      macaddress
      username
      password
      status
      lasterror
      relaynumber
      alertminute
      alerthour
      controllerid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      schedules {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cameraControllerId
      __typename
    }
  }
`;
export const deleteCamera = /* GraphQL */ `
  mutation DeleteCamera(
    $input: DeleteCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    deleteCamera(input: $input, condition: $condition) {
      id
      name
      model
      active
      type
      ipaddress
      macaddress
      username
      password
      status
      lasterror
      relaynumber
      alertminute
      alerthour
      controllerid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      schedules {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cameraControllerId
      __typename
    }
  }
`;
export const createController = /* GraphQL */ `
  mutation CreateController(
    $input: CreateControllerInput!
    $condition: ModelControllerConditionInput
  ) {
    createController(input: $input, condition: $condition) {
      id
      name
      description
      dhcp
      ipaddress
      netmask
      gateway
      dns
      armall
      arn
      deviceid
      debug
      alerts {
        nextToken
        startedAt
        __typename
      }
      sensors {
        nextToken
        startedAt
        __typename
      }
      accountid
      locationid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateController = /* GraphQL */ `
  mutation UpdateController(
    $input: UpdateControllerInput!
    $condition: ModelControllerConditionInput
  ) {
    updateController(input: $input, condition: $condition) {
      id
      name
      description
      dhcp
      ipaddress
      netmask
      gateway
      dns
      armall
      arn
      deviceid
      debug
      alerts {
        nextToken
        startedAt
        __typename
      }
      sensors {
        nextToken
        startedAt
        __typename
      }
      accountid
      locationid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteController = /* GraphQL */ `
  mutation DeleteController(
    $input: DeleteControllerInput!
    $condition: ModelControllerConditionInput
  ) {
    deleteController(input: $input, condition: $condition) {
      id
      name
      description
      dhcp
      ipaddress
      netmask
      gateway
      dns
      armall
      arn
      deviceid
      debug
      alerts {
        nextToken
        startedAt
        __typename
      }
      sensors {
        nextToken
        startedAt
        __typename
      }
      accountid
      locationid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      controllerid
      sensorid
      locationid
      ping
      alert
      active
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      controllerid
      sensorid
      locationid
      ping
      alert
      active
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      controllerid
      sensorid
      locationid
      ping
      alert
      active
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      description
      accountid
      controllers {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      cameras {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      description
      accountid
      controllers {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      cameras {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      description
      accountid
      controllers {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      cameras {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPing = /* GraphQL */ `
  mutation CreatePing(
    $input: CreatePingInput!
    $condition: ModelPingConditionInput
  ) {
    createPing(input: $input, condition: $condition) {
      id
      controllerid
      locationid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePing = /* GraphQL */ `
  mutation UpdatePing(
    $input: UpdatePingInput!
    $condition: ModelPingConditionInput
  ) {
    updatePing(input: $input, condition: $condition) {
      id
      controllerid
      locationid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePing = /* GraphQL */ `
  mutation DeletePing(
    $input: DeletePingInput!
    $condition: ModelPingConditionInput
  ) {
    deletePing(input: $input, condition: $condition) {
      id
      controllerid
      locationid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      name
      description
      port
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      payload
      cameraid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      name
      description
      port
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      payload
      cameraid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      name
      description
      port
      armtime
      disarmtime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      payload
      cameraid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      name
      description
      alertinterval
      alerttime
      sensorpin
      relaytype
      tamperpin
      locationid
      lastcheck
      controllerid
      actions {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      name
      description
      alertinterval
      alerttime
      sensorpin
      relaytype
      tamperpin
      locationid
      lastcheck
      controllerid
      actions {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      name
      description
      alertinterval
      alerttime
      sensorpin
      relaytype
      tamperpin
      locationid
      lastcheck
      controllerid
      actions {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUnregistered = /* GraphQL */ `
  mutation CreateUnregistered(
    $input: CreateUnregisteredInput!
    $condition: ModelUnregisteredConditionInput
  ) {
    createUnregistered(input: $input, condition: $condition) {
      id
      name
      arn
      thing
      registered
      message
      locationid
      controllerid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUnregistered = /* GraphQL */ `
  mutation UpdateUnregistered(
    $input: UpdateUnregisteredInput!
    $condition: ModelUnregisteredConditionInput
  ) {
    updateUnregistered(input: $input, condition: $condition) {
      id
      name
      arn
      thing
      registered
      message
      locationid
      controllerid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUnregistered = /* GraphQL */ `
  mutation DeleteUnregistered(
    $input: DeleteUnregisteredInput!
    $condition: ModelUnregisteredConditionInput
  ) {
    deleteUnregistered(input: $input, condition: $condition) {
      id
      name
      arn
      thing
      registered
      message
      locationid
      controllerid
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      permissions
      cognito
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      permissions
      cognito
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      permissions
      cognito
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
