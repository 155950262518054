export default function validate(values) {
  let errors = {}

  if (!values.ipaddress) {
    errors.ipaddress = 'IP address is required';
  } else if (!ValidateIPaddress(values.ipaddress)) {
    errors.ipaddress = 'IP address is invalid';
  }
  if (!values.netmask) {
    errors.netmask = 'Subnet Mask is required';
  } else if (!ValidateIPaddress(values.netmask)) {
    errors.netmask = 'Subnet Mask is invalid';
  }
  if (!values.gateway) {
    errors.gateway = 'Gateway is required';
  } else if (!ValidateIPaddress(values.gateway)) {
    errors.gateway = 'Gateway is invalid';
  }
  if (!values.dns) {
    errors.dns = 'DNS Server is required';
  } else if (!ValidateIPaddress(values.dns)) {
    errors.dns = 'DNS Server is invalid';
  }

  function ValidateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
      return (true)
    }
    //alert("You have entered an invalid IP address!")
    return (false)
  }

  return errors
}
