import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { MDBInputGroup, MDBInput, MDBTextArea } from 'mdb-react-ui-kit'

export default function Post() {
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const [exjson, setExjson] = useState('')
  const [status, setStatus] = useState(0)
  const [data, setData] = useState('')
  const [credentials, setCredentials] = useState([])

  async function handleClick() {
    var payload = JSON.parse(exjson)
    console.log(payload)

    try {
      const lambdaClient = new LambdaClient({
        region: AWS_REGION,
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })
      const command = new InvokeCommand({
        FunctionName: `banditmqtt-${USER_BRANCH}`,
        Payload: JSON.stringify(payload),
      })
      const response = await lambdaClient.send(command)
      console.log(response) // Update state with Lambda response
    } catch (error) {
      console.error('Error invoking Lambda:', error)
    }
  }

  function setInput(value) {
    setExjson(value)
  }

  useEffect(() => {
    async function loadCred() {
      try {
        const creds = await Auth.currentUserCredentials()
        setCredentials(creds)
      } catch (error) {
        console.error(error)
      }
    }
    loadCred()
  }, [])

  return (
    <React.Fragment>
      <Container>
        <h2>Post</h2>
        <hr />
        <MDBTextArea label='Enter JSON' id='textAreaExample' value={exjson} rows={10} className='mb-3' onChange={(event) => setInput(event.target.value)} />
        <Button
          onClick={handleClick}
          style={{
            textAlign: 'center',
            width: '320px',
            border: '1px solid gray',
            borderRadius: '5px',
            marginBottom: '10px',
          }}>
          Send data to backend
        </Button>
        <MDBInputGroup textBefore='Status' className='mb-3'>
          <MDBInput id='status' value={status} type='text' className='mb-3' />
        </MDBInputGroup>
        <MDBTextArea id='lambda' value={data} rows={10} className='mb-3' />
      </Container>
    </React.Fragment>
  )
}
