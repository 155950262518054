import React, { useState, useEffect, useMemo } from 'react'
import { API, graphqlOperation } from '@aws-amplify/api'
import { Link } from 'react-router-dom'
import * as bandit from '../../graphql/bandit'
import * as queries from '../../graphql/queries'
import { alertsbyType } from '../../graphql/bandit'
import * as mutations from '../../graphql/mutations'
import { Toast, ToastContainer } from 'react-bootstrap'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { MDBIcon, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBadge } from 'mdb-react-ui-kit'
import moment from 'moment'
import logo from '../../art/bandit.svg'

var _ = require('lodash')

export default function Dashboard() {
  const [activeCameras, setActiveCameras] = useState(0)
  const [warnings, setWarnings] = useState(0)
  const [errors, setErrors] = useState(0)
  const [data, setAlertData] = useState([])
  const [dummy, setDummy] = useState([])
  const [seconds, setSeconds] = useState(0)
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, loadedRows: 0 })
  const [isError, setIsError] = useState(false)
  const [nextToken, setNextToken] = useState(undefined)
  const limit = 100

  const columns = useMemo(() => [
    {
      header: 'Type',
      accessorKey: 'type',
      maxSize: 20,
      enableSorting: true,
      Cell: ({ cell }) => {
        return (
          <React.Fragment>
            {' '}
            {cell.getValue() == 'error' ? (
              <MDBBadge pill color='danger'>
                {cell.getValue()}
              </MDBBadge>
            ) : (
              <MDBBadge pill color={cell.getValue()}>
                {cell.getValue()}
              </MDBBadge>
            )}
          </React.Fragment>
        )
      },
    },
    { header: 'Message', accessorKey: 'message' },
    {
      header: 'Date Created',
      accessorKey: 'createdAt',
      Cell: ({ cell }) => {
        return <React.Fragment> {moment(cell.getValue()).format('MMMM Do YYYY, h:mm:ss a')}</React.Fragment>
      },
    },
    {
      accessorFn: (row) => `${row.location} ${row.locationid}`, //accessorFn used to join multiple data into a single cell
      id: 'location', //id is still required when using accessorFn instead of accessorKey
      header: 'Location',
      maxSize: 20,
      Cell: ({ renderedCellValue, row }) => (
        <Link to={'/location/' + row.original.locationid}>
          <MDBBtn size='sm' className='message-btn ms-2'>
            <MDBIcon icon='map-marker-alt' /> {row.original.location?.name}
          </MDBBtn>
        </Link>
      ),
    },
    {
      header: 'View',
      accessorKey: 'id',
      id: 'view',
      maxSize: 20,
      Cell: ({ cell }) => {
        return (
          <React.Fragment>
            <MDBBtn size='sm' floating className='message-btn ms-2'>
              <Link to={'/alert/' + cell.getValue()}>
                <MDBIcon icon='eye' />
              </Link>
            </MDBBtn>
          </React.Fragment>
        )
      },
    },
    {
      header: 'Hide',
      accessorKey: 'id',
      maxSize: 20,
      Cell: ({ cell }) => {
        return (
          <React.Fragment>
            <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => hideAlert(cell.getValue())}>
              <MDBIcon icon='ban' />
            </MDBBtn>
          </React.Fragment>
        )
      },
    },
  ])

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    onPaginationChange: setPagination,
    state: {
      isLoading,
      pagination
    }, 
    muiTablePaperProps: {
      elevation: 0
    },
    muiCircularProgressProps: {
      color: 'primary'
    }
  })

  async function getAlerts() {
    try {
      var moah = []
      setDummy((prevState) => {
        return {
          ...prevState,
          rows: moah.map((row) => {
            return {
              ...row,
              timestamp: <React.Fragment>{moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</React.Fragment>,
              typed: (
                <React.Fragment>
                  {row.type == 'error' ? (
                    <MDBBadge pill color='danger'>
                      {row.type}
                    </MDBBadge>
                  ) : (
                    <MDBBadge pill color={row.type}>
                      {row.type}
                    </MDBBadge>
                  )}
                </React.Fragment>
              ),
              details: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2'>
                    <Link to={'/alert/' + row.id}>
                      <MDBIcon icon='eye' />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              hide: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => hideAlert()}>
                    <MDBIcon icon='ban' />
                  </MDBBtn>
                </React.Fragment>
              ),
              loc: (
                <React.Fragment>
                  <Link to={'/location/' + row.locationid}>{row.location?.name}</Link>
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function getControllers() {
    try {
      const cntrldata = await API.graphql({ query: queries.listControllers })
      setActiveCameras(cntrldata.data.listControllers.items.length)
    } catch (err) {
      console.log(err)
    }
  }

  async function hideAlert(id) {
    var input = {
      id: id,
      active: false,
      _version: 1,
    }
    try {
      await API.graphql({ query: mutations.updateAlert, variables: { input: input } })
      setToastContent({ header: '', action: 'hidden', content: `Alert ${id} ` })
      setToast(true)
    } catch (err) {
      console.log(err)
    }
    let filtered = _.reject(data, {'id' : id})
    setAlertData(filtered)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1)
      if (seconds == 0) {
        getAlerts()
        getControllers()
        console.log('Alerts Refreshed')
      } else if (seconds >= 30) {
        setSeconds(0)
      }
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    const fetch = async (load) => {
      setIsLoading(true)
      try {
        const variables = {
          nextToken,
          limit: 100000,
          filter: {
            active: {
              eq: true,
            },
            type: {
              ne: 'info',
            },
          },
        }
        const etype = await API.graphql(
          graphqlOperation(alertsbyType, {
            type: 'error',
            limit: 100000,
            filter: {
              active: {
                eq: true,
              },
            },
          })
        )
        const wtype = await API.graphql(
          graphqlOperation(alertsbyType, {
            type: 'warning',
            limit: 100000,
            filter: {
              active: {
                eq: true,
              },
            },
          })
        )
        const etypes = etype.data.alertsbyType.items
        const wtypes = wtype.data.alertsbyType.items
        const result = await API.graphql({ query: queries.listAlerts, variables })
        setNextToken(result.data.listAlerts.nextToken)
        //var alerts = etypes.concat(wtypes)
        var alerts = result.data.listAlerts.items
        alerts = _.orderBy(alerts, ['createdAt'], ['desc'])
        let warning = _.filter(alerts, ['type', 'warning'])
        let error = _.filter(alerts, ['type', 'error'])
        alerts = _.orderBy(alerts, ['createdAt'], ['desc'])
        setWarnings(warning.length)
        setErrors(error.length)
        var newLoad = pagination.loadedRows + alerts.length
        if (load === 1) {
          setAlertData(alerts)
        } else {
          setAlertData([...data, ...alerts])
        }
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      } finally {
        setIsLoading(false)
        setPagination({ ...pagination, pageIndex: paged, loadedRows: newLoad })
      }
    }
    var paged = pagination.pageIndex
    if (pagination.loadedRows === 0) {
      fetch(1)
      return
    } else if (pagination.pageIndex * pagination.pageSize + pagination.pageSize !== pagination.loadedRows) {
      return
    }
    fetch(0)
  }, [nextToken, pagination.pageIndex, pagination.pageSize])

  return (
    <MDBContainer>
      <MDBRow className='dashboard my-3'>
        <MDBCol xs={6} md={4}>
          <MDBCard>
            <MDBCardBody>
              <MDBIcon fas icon='exclamation-triangle' size='10x' color='danger' pull='left' />
              <h2 className='text-danger'>{errors}</h2>
              <p>Errors</p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xs={6} md={4}>
          <MDBCard>
            <MDBCardBody>
              <MDBIcon fas icon='question-circle' size='10x' color='warning' pull='left' />
              <h2 className='text-warning'>{warnings}</h2>
              <p>Warnings</p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xs={6} md={4}>
          <MDBCard>
            <MDBCardBody>
              <MDBIcon fas icon='gamepad' size='10x' color='success' pull='left' />
              <h2 className='text-success'>{activeCameras}</h2>
              <p>Active Controllers</p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MaterialReactTable
          table={table}
        />
      </MDBRow>
      <ToastContainer position='middle-center'>
        <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img src={logo} className='me-2' alt='' width='32px' />
            <strong className='me-auto'>Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {toastContent.content} has been {toastContent.action}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </MDBContainer>
  )
}
