import React, { useState, useEffect, useRef } from 'react'
import { SchedulerClient, UpdateScheduleCommand, ListSchedulesCommand } from '@aws-sdk/client-scheduler'
import { API } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
import { useLocation } from 'react-router-dom'
import { vportops } from './Options'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBInput, MDBCheckbox, MDBInputGroup, MDBTextArea, MDBTimepicker, MDBSwitch, MDBToast } from 'mdb-react-ui-kit'
import Select from 'react-select'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import logo from '../../art/bandit.svg'

var _ = require('lodash')

export default function Schedule() {
  const location = useLocation()
  let scheduleid = location.pathname
  scheduleid = scheduleid.replace('/schedule/', '')
  const AWS_REGION = process.env.REACT_APP_AWS_REGION
  const AWS_ACC = process.env.REACT_APP_AWS_ACC
  const APP_ROLE = process.env.REACT_APP_APP_ROLE
  const USER_BRANCH = process.env.REACT_APP_USER_BRANCH
  const [event, setEvent] = useState([])
  const [theSchedule, setTheSchedule] = useState([])
  const [camera, setCamera] = useState()
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })
  const [armtime, setArmtime] = useState()
  const [disarmtime, setDisarmtime] = useState()
  const [scheduleModal, setScheduleModal] = useState(false)
  const toggleSchedule = () => setScheduleModal(!scheduleModal)
  const [scheduleSwitch, setScheduleSwitch] = useState(false)
  const [weekdays, setWeekdays] = useState([])
  const [ebsonExpression, setEbsonExpression] = useState(['*', '*', '?', '*', '*', '*'])
  const [ebsoffExpression, setEbsoffExpression] = useState(['*', '*', '?', '*', '*', '*'])
  const [noday, setNoday] = useState(false)

  const config = {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    region: AWS_REGION,
  }
  const client = new SchedulerClient(config)

  function allDays() {
    setScheduleSwitch(!scheduleSwitch)
    if (!scheduleSwitch) {
      const updatedOn = [...ebsonExpression]
      updatedOn[4] = '1,2,3,4,5,6,7'
      setEbsonExpression(updatedOn)
      const updatedOff = [...ebsoffExpression]
      updatedOff[4] = '1,2,3,4,5,6,7'
      setEbsoffExpression(updatedOff)
      setWeekdays(['1', '2', '3', '4', '5', '6', '7'])
      setTheSchedule({ ...theSchedule, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true })
    } else {
      const updatedOn = [...ebsonExpression]
      updatedOn[4] = ''
      setEbsonExpression(updatedOn)
      const updatedOff = [...ebsoffExpression]
      updatedOff[4] = ''
      setEbsoffExpression(updatedOff)
      setWeekdays([])
      setTheSchedule({ ...theSchedule, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false })
    }
  }

  function setTheArmtime(v) {
    const [hour, minute] = v.split(':')
    const updatedCron = [...ebsonExpression]
    updatedCron[0] = String(Number(minute))
    updatedCron[1] = String(Number(hour))
    setEbsonExpression(updatedCron)
    setArmtime(v)
  }

  function setTheDisarmtime(v) {
    const [hour, minute] = v.split(':')
    const updatedCron = [...ebsoffExpression]
    updatedCron[0] = String(Number(minute))
    updatedCron[1] = String(Number(hour))
    setEbsoffExpression(updatedCron)
    setDisarmtime(v)
  }

  function setSchedule(k, v) {
    switch (k) {
      case 'monday': //
        if (v) {
          setWeekdays([...weekdays, '2'])
        } else {
          let newdays = weekdays.filter((t) => t !== '2')
          setWeekdays(newdays)
        }
        break
      case 'tuesday': //
        if (v) {
          setWeekdays([...weekdays, '3'])
        } else {
          const newdays = weekdays.filter((t) => t !== '3')
          setWeekdays(newdays)
        }
        break
      case 'wednesday': //
        if (v) {
          setWeekdays([...weekdays, '4'])
        } else {
          const newdays = weekdays.filter((t) => t !== '4')
          setWeekdays(newdays)
        }
        break
      case 'thursday': //
        if (v) {
          setWeekdays([...weekdays, '5'])
        } else {
          const newdays = weekdays.filter((t) => t !== '5')
          setWeekdays(newdays)
        }
        break
      case 'friday': //
        if (v) {
          setWeekdays([...weekdays, '6'])
        } else {
          const newdays = weekdays.filter((t) => t !== '6')
          setWeekdays(newdays)
        }
        break
      case 'saturday': //
        if (v) {
          setWeekdays([...weekdays, '7'])
        } else {
          const newdays = weekdays.filter((t) => t !== '7')
          setWeekdays(newdays)
        }
        break
      case 'sunday': //
        if (v) {
          setWeekdays([...weekdays, '1'])
        } else {
          const newdays = weekdays.filter((t) => t !== '1')
          setWeekdays(newdays)
        }
        break
      default:
        //console.log(k)
        break
    }
    setTheSchedule({ ...theSchedule, [k]: v })
  }

  async function onLoad() {
    try {
      const scheduledata = await API.graphql({ query: queries.getSchedule, variables: { id: scheduleid } })
      const loadedschedule = scheduledata.data.getSchedule
      setTheSchedule(loadedschedule)
      let at = loadedschedule.armtime.slice(0, -3)
      setTheArmtime(at)
      let dt = loadedschedule.disarmtime.slice(0, -3)
      setTheDisarmtime(dt)
      const camdata = await API.graphql({ query: queries.getCamera, variables: { id: loadedschedule.cameraid } })
      setCamera(camdata.data.getCamera.name)
      let theweek = []
      if (loadedschedule.monday) {
        theweek.push('2')
      }
      if (loadedschedule.tuesday) {
        theweek.push('3')
      }
      if (loadedschedule.wednesday) {
        theweek.push('4')
      }
      if (loadedschedule.thursday) {
        theweek.push('5')
      }
      if (loadedschedule.friday) {
        theweek.push('6')
      }
      if (loadedschedule.saturday) {
        theweek.push('7')
      }
      if (loadedschedule.sunday) {
        theweek.push('1')
      }
      setWeekdays(theweek)
    } catch (err) {
      console.log(err)
    }
  }

  function validateSchedule() {
    if (weekdays.length == 0) {
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected any days for the schedule' })
      setToast(true)
      return
    }
    if (ebsonExpression[0] == 'NaN' || ebsonExpression[0] == '*' || ebsonExpression[1] == 'NaN' || ebsonExpression[1] == '*' ){
      setToastContent({ ...toastContent, content: 'ERROR : You need to select an ENABLE time for the schedule' })
      setToast(true)
      return
    }
    if (ebsoffExpression[0] == 'NaN' || ebsoffExpression[0] == '*' || ebsoffExpression[1] == 'NaN' || ebsoffExpression[1] == '*' ){
      setToastContent({ ...toastContent, content: 'ERROR : You need to select an DISABLE time for the schedule' })
      setToast(true)
      return
    }
    if (theSchedule.port == 0 ){
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected a port for the camera' })
      setToast(true)
      return
    }
    if (theSchedule.name == '' ){
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected a name for the schedule' })
      setToast(true)
      return
    }
    updateSchedule()
  }

  async function updateSchedule() {
    console.log(theSchedule)
    if (weekdays.length == 0) {
      setToastContent({ ...toastContent, content: 'ERROR : You have not selected any days for the schedule' })
      setToast(true)
      return
    }
    const ebson = {
      Name: theSchedule.id + '1', // required
      GroupName: theSchedule.cameraid,
      ScheduleExpression: 'cron(' + ebsonExpression.join(' ') + ')',
      Description: 'ON : ' + theSchedule.name + ' - ' + theSchedule.description,
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:banditmqtt-${USER_BRANCH}`, // required
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`, // required
        Input: `{ \"action\": \"ON\", ${theSchedule.payload} }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF', // required
      },
    }
    const ebsoff = {
      Name: theSchedule.id + '0', // required
      GroupName: theSchedule.cameraid,
      ScheduleExpression: 'cron(' + ebsoffExpression.join(' ') + ')',
      Description: 'OFF : ' + theSchedule.name + ' - ' + theSchedule.description,
      Target: {
        Arn: `arn:aws:lambda:${AWS_REGION}:${AWS_ACC}:function:banditmqtt-${USER_BRANCH}`, // required
        RoleArn: `arn:aws:iam::${AWS_ACC}:role/service-role/${APP_ROLE}`, // required
        Input: `{ \"action\": \"OFF\", ${theSchedule.payload} }`,
      },
      FlexibleTimeWindow: {
        Mode: 'OFF', // required
      },
    }
    try {
      let schedulehead = theSchedule
      schedulehead.armtime = armtime + ':00'
      schedulehead.disarmtime = disarmtime + ':59'
      let cleaned = _.omit(schedulehead, ['cameras', 'createdAt', 'updatedAt', '_deleted', '_lastChangedAt', '__typename'])
      await API.graphql({ query: mutations.updateSchedule, variables: { input: cleaned } })
      setToastContent({ ...toastContent, content: theSchedule.name + ' has been updated.' })
      setToast(true)
      onLoad()
    } catch (err) {
      console.log(err)
    }
    try {
      const ebsonc = new UpdateScheduleCommand(ebson)
      const ebsonr = await client.send(ebsonc)
      const ebsoffc = new UpdateScheduleCommand(ebsoff)
      const ebsoffr = await client.send(ebsoffc)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const updatedOn = [...ebsonExpression]
    updatedOn[4] = String(weekdays)
    setEbsonExpression(updatedOn)
    const updatedOff = [...ebsoffExpression]
    updatedOff[4] = String(weekdays)
    setEbsoffExpression(updatedOff)
  }, [weekdays])

  useEffect(() => {
    onLoad()
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <MDBContainer className='content'>
        <MDBRow>
          <h2>
            <a href={'/camera/' + theSchedule.cameraid}>{camera}</a> / {theSchedule.name || ''}
          </h2>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <MDBRow className='my-3 form-selects'>
          <MDBCol md='6'>
            <MDBInputGroup textBefore='Name' className='mb-3'>
              <MDBInput id='name' value={theSchedule.name || ''} type='text' onChange={(event) => setSchedule('name', event.target.value)} />
            </MDBInputGroup>
          </MDBCol>
          <MDBCol md='6'>

            <MDBInputGroup textBefore={'VIO'} className='mb-3'>
                    <Select
                      className='react-select'
                      options={vportops}
                      placeholder={theSchedule.port || ''}
                      onChange={(e) => setSchedule('port', e.value)}
                    />
                  </MDBInputGroup>
          </MDBCol>
          <MDBCol md='12' className='mb-3'>
            <MDBTextArea
              label='Description'
              value={theSchedule.description || ''}
              rows={3}
              onChange={(event) => setSchedule('description', event.target.value)}
              id='description'
            />
          </MDBCol>
          <MDBCol md='6'>
            <MDBInputGroup textBefore='Enable Time' className='mb-3'>
              <MDBTimepicker inline format='24h' defaultValue={armtime} value={armtime} onChange={setTheArmtime} />
            </MDBInputGroup>
          </MDBCol>
          <MDBCol md='6'>
            <MDBInputGroup textBefore='Disable Time' className='mb-4'>
              <MDBTimepicker inline format='24h' defaultValue={disarmtime} value={disarmtime} onChange={setTheDisarmtime} />
            </MDBInputGroup>
          </MDBCol>
          <MDBCol md='12' className='mb-3 d-flex justify-content-between'>
            <MDBSwitch id='scheduleDays' label='ALL' value={scheduleSwitch} onClick={allDays} />
            <MDBCheckbox
              name='monday'
              value='monday'
              id='MON'
              label='MON'
              aria-label='...'
              inline
              checked={theSchedule.monday}
              onChange={(event) => setSchedule('monday', event.target.checked)}
            />
            <MDBCheckbox
              name='tuesday'
              value='tuesday'
              id='TUE'
              label='TUE'
              aria-label='...'
              inline
              checked={theSchedule.tuesday}
              onChange={(event) => setSchedule('tuesday', event.target.checked)}
            />
            <MDBCheckbox
              name='wednesday'
              value='wednesday'
              id='WED'
              label='WED'
              aria-label='...'
              inline
              checked={theSchedule.wednesday}
              onChange={(event) => setSchedule('wednesday', event.target.checked)}
            />
            <MDBCheckbox
              name='thursday'
              value='thursday'
              id='THU'
              label='THU'
              aria-label='...'
              inline
              checked={theSchedule.thursday}
              onChange={(event) => setSchedule('thursday', event.target.checked)}
            />
            <MDBCheckbox
              name='friday'
              value='friday'
              id='FRI'
              label='FRI'
              aria-label='...'
              inline
              checked={theSchedule.friday}
              onChange={(event) => setSchedule('friday', event.target.checked)}
            />
            <MDBCheckbox
              name='saturday'
              value='saturday'
              id='SAT'
              label='SAT'
              aria-label='...'
              inline
              checked={theSchedule.saturday}
              onChange={(event) => setSchedule('saturday', event.target.checked)}
            />
            <MDBCheckbox
              name='sunday'
              value='sunday'
              id='SUN'
              label='SUN'
              aria-label='...'
              inline
              checked={theSchedule.sunday}
              onChange={(event) => setSchedule('sunday', event.target.checked)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className='my-3'>{event.Arn}</MDBRow>
        <MDBRow className='my-3'>
          <MDBCol md='10'></MDBCol>
          <MDBCol md='2' className='d-flex flex-row-reverse'>
            <MDBBtn onClick={validateSchedule}>Update VIO Schedule</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer position='middle-center'>
        <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img src={logo} className='me-2' alt='Bandit Logo' width='32px' />
            <strong className='me-auto'>Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>{toastContent.content}</Toast.Body>
        </Toast>
      </ToastContainer>
    </React.Fragment>
  )
}
