export const getCamera = /* GraphQL */ `
  query GetCamera($id: ID!) {
    getCamera(id: $id) {
      id
      name
      model
      active
      type
      ipaddress
      macaddress
      username
      password
      status
      lasterror
      relaynumber
      alertminute
      alerthour
      controllerid
      controller {
        id
        name
        description
        dhcp
        ipaddress
        netmask
        gateway
        dns
        armall
        arn
        deviceid
        debug
        accountid
        locationid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      locationid
      schedules(filter: { _deleted: { ne: true } })  {
        items {
          id
          name
          description
          port
          armtime
          disarmtime
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          cameraid
          _version
          _deleted
          createdAt
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cameraControllerId
      __typename
    }
  }
`

export const alertsbyType = /* GraphQL */ `
  query AlertsbyType(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsbyType(type: $type, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        location {
          id
          name
        }
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`
export const listAccounts = /* GraphQL */ `
  query ListAccounts($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locations(filter: { _deleted: { ne: true } }) {
          items {
            name
            id
          }
        }
      }
      nextToken
      startedAt
    }
  }
`
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      locations(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          description
          _version
          _deleted
          createdAt
          alerts(filter: { _deleted: { ne: true } }) {
            items {
              id
              message
              type
              code
              locationid
              _version
              _deleted
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      name
      description
      type
      relaynumber
      portnumber
      sensorid
      controllerid
      camera {
        id
        name
        model
        type
        ipaddress
        username
        password
        active
        status
        lasterror
        relaynumber
        alertminute
        alerthour
        locationid
        controllerid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      actionCameraId
      blocks(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          description
          armtime
          disarmtime
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          actionid
          _version
          _deleted
          createdAt
        }
        nextToken
        startedAt
      }
    }
  }
`
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      description
      accountid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      cameras(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          model
          type
          ipaddress
          username
          password
          active
          status
          lasterror
          relaynumber
          alertminute
          alerthour
          locationid
          schedules(filter: { _deleted: { ne: true } })  {
            items {
              id
              name
              description
              port
              armtime
              disarmtime
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
              cameraid
              _version
              _deleted
              createdAt
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      controllers(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          description
          dhcp
          ipaddress
          netmask
          gateway
          dns
          armall
          arn
          _version
          _deleted
          createdAt
          sensors(filter: { _deleted: { ne: true } }) {
            items {
              id
              name
              description
              lastcheck
              sensorpin
              relaytype
              tamperpin
              locationid
              controllerid
              actions(filter: { _deleted: { ne: true } }) {
                items {
                  id
                  name
                  description
                  type
                  relaynumber
                  portnumber
                  controllerid
                  sensorid
                  actionCameraId
                  blocks(filter: { _deleted: { ne: true } }) {
                    items {
                      id
                      name
                      description
                      armtime
                      disarmtime
                      monday
                      tuesday
                      wednesday
                      thursday
                      friday
                      saturday
                      sunday
                      actionid
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                    }
                  }
                  _version
                  _deleted
                  createdAt
                }
              }
              _version
              _deleted
              createdAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`
export const getController = /* GraphQL */ `
  query GetController($id: ID!) {
    getController(id: $id) {
      id
      name
      description
      arn
      debug
      dhcp
      ipaddress
      netmask
      gateway
      dns
      armall
      accountid
      locationid
      sensors(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          description
          lastcheck
          sensorpin
          relaytype
          tamperpin
          locationid
          controllerid
          actions(filter: { _deleted: { ne: true } }) {
            items {
              id
              name
              description
              type
              relaynumber
              portnumber
              controllerid
              sensorid
              actionCameraId
              camera {
                id
                name
                model
                active
                type
                ipaddress
                username
                password
                status
                lasterror
                relaynumber
                alertminute
                alerthour
                controllerid
                locationid
                _version
                _deleted
                createdAt
              }
              blocks(filter: { _deleted: { ne: true } }) {
                items {
                  id
                  name
                  description
                  armtime
                  disarmtime
                  monday
                  tuesday
                  wednesday
                  thursday
                  friday
                  saturday
                  sunday
                  actionid
                  _version
                  _deleted
                  createdAt
                }
                nextToken
                startedAt
              }
              _version
              _deleted
              createdAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          createdAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      name
      description
      alertinterval
      alerttime
      lastcheck
      sensorpin
      relaytype
      tamperpin
      controllerid
      actions(filter: { _deleted: { ne: true } }) {
        items {
          id
          name
          description
          sensorid
          actionCameraId
          _version
          _deleted
          createdAt
          camera {
            id
            name
            model
            type
            ipaddress
            username
            password
            active
            status
            lasterror
            relaynumber
            alertminute
            alerthour
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      message
      type
      code
      accountid
      locationid
      _version
      _deleted
      _lastChangedAt
    }
  }
`

export const listAlerts = /* GraphQL */ `
  query ListAlerts($filter: ModelAlertFilterInput, $limit: Int, $nextToken: String) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        type
        code
        devicepin
        active
        arn
        thing
        accountid
        controllerid
        sensorid
        createdAt
        updatedAt
        lastChangedAt
        locationid
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`

export const listUnregistereds = /* GraphQL */ `
  query ListUnregistereds($filter: ModelUnregisteredFilterInput, $limit: Int, $nextToken: String) {
    listUnregistereds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        arn
        thing
        registered
        createdAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`
