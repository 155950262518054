import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import * as bandit from '../../graphql/bandit'
import * as mutations from '../../graphql/mutations'
import { Link, useLocation } from 'react-router-dom'
import {
  MDBContainer,
  MDBDatatable,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInputGroup,
} from 'mdb-react-ui-kit'
import { Badge, Toast, ToastContainer } from 'react-bootstrap'
import logo from '../../art/bandit.svg'

var _ = require('lodash')

export default function Account() {
  const location = useLocation()
  let accountid = location.pathname
  accountid = accountid.replace('/account/', '')

  const [formState, setFormState] = useState([{ name: '', notes: '' }])
  const [newLocation, setNewLocation] = useState({ name: '', description: '', accountid: accountid })
  const [locationList, setLocationList] = useState({
    columns: [
      { label: 'Name', field: 'name', sort: true },
      { label: 'Alerts', field: 'alerts', sort: false },
      { label: 'Description', field: 'description', sort: false },
      { label: 'Edit', field: 'edit', sort: false },
      { label: 'Delete', field: 'delete', sort: false },
    ],
    rows: [],
  })
  const [locationModal, setLocationModal] = useState(false)
  const toggleLocation = () => setLocationModal(!locationModal)
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({ header: '', action: '', content: '' })

  async function onLoad() {
    try {
      const accountdata = await API.graphql({ query: bandit.getAccount, variables: { id: accountid } })
      let accdata = accountdata.data.getAccount
      if (accdata.notes == null) {
        accdata.notes = ''
      }
      setFormState(accdata)
      let loclist = accdata.locations.items
      loclist = _.reject(loclist, ['_deleted', true])
      setLocationList((prevState) => {
        return {
          ...prevState,
          rows: loclist.map((row) => {
            return {
              ...row,
              alerts: (
                <React.Fragment>
                  {row.alerts.items.length > 0 ? (
                    <Badge pill bg='warning'>
                      {row.alerts.items.length}
                    </Badge>
                  ) : (
                    <Badge pill bg='success'>
                      {row.alerts.items.length}
                    </Badge>
                  )}
                </React.Fragment>
              ),
              edit: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2'>
                    <Link to={'/location/' + row.id}>
                      <MDBIcon icon='edit' />
                    </Link>
                  </MDBBtn>
                </React.Fragment>
              ),
              delete: (
                <React.Fragment>
                  <MDBBtn size='sm' floating className='message-btn ms-2' onClick={() => trashLocation(row.name, row.id, row._version)}>
                    <MDBIcon icon='trash' />
                  </MDBBtn>
                </React.Fragment>
              ),
            }
          }),
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  async function updateAccount() {
    try {
      let cleaned = _.omit(formState, [
        'alerts',
        'locations',
        'controllers',
        'sensors',
        'cameras',
        'devices',
        'createdAt',
        'updatedAt',
        '_deleted',
        '_lastChangedAt',
      ])
      await API.graphql({ query: mutations.updateAccount, variables: { input: cleaned } })
      setToastContent({ ...toastContent, content: cleaned.name, action: 'updated' })
      setToast(true)
      setFormState(cleaned)
    } catch (err) {
      console.log(err)
    }
  }

  async function addLocation() {
    try {
      await API.graphql({ query: mutations.createLocation, variables: { input: newLocation } })
      setToastContent({ header: '', action: 'created', content: newLocation.name })
      setToast(true)
      setLocationModal(false)
      setNewLocation({ name: '', description: '', armed: false, accountid: accountid })
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  async function trashLocation(name, id, version) {
    const data = {
      id: id,
      _version: version,
    }
    try {
      await API.graphql({ query: mutations.deleteLocation, variables: { input: data } })
      setToastContent({ header: '', action: 'deleted', content: name })
      setToast(true)
      onLoad()
    } catch (err) {
      console.log(err)
    }
  }

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  function setLocation(key, value) {
    setNewLocation({ ...newLocation, [key]: value })
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <MDBContainer>
        <MDBRow>
          <h2>Account</h2>
          <hr />
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <div className='d-flex flex-row justify-content-between w-100 mb-3 pb-3 border-bottom'>
          <form className='d-flex flex-row justify-content-between w-100'>
            <div className='me-3'>
              <MDBInput label='Account Name' id='name' value={formState.name || ''} type='text' onChange={(event) => setInput('name', event.target.value)} />
            </div>
            <div className='flex-grow-1 me-3'>
              <MDBTextArea label='Notes' value={formState.notes || ''} rows={3} onChange={(event) => setInput('notes', event.target.value)} id='notes' />
            </div>
          </form>
          <div className='ms-5'>
            <MDBBtn onClick={updateAccount}>Update Account</MDBBtn>
          </div>
        </div>
      </MDBContainer>
      <MDBContainer>
        <div className='d-flex flex-row justify-content-between w-100 mt-5 mb-3 pb-3 border-bottom'>
          <h4>Locations</h4>
          <MDBBtn onClick={toggleLocation}>
            <MDBIcon fas icon='plus-square' /> &nbsp;Add Location
          </MDBBtn>
        </div>
        <MDBDatatable data={locationList} />
      </MDBContainer>
      <MDBModal open={locationModal} setOpen={setLocationModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Location to {formState.name} </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className='my-3 form-selects'>
                <MDBCol md='12'>
                  <MDBInputGroup textBefore='Location Name' className='mb-3'>
                    <MDBInput id='name' value={newLocation.name || ''} type='text' onChange={(event) => setLocation('name', event.target.value)} />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol md='12'>
                  <MDBTextArea
                    label='Description'
                    value={newLocation.description || ''}
                    rows={3}
                    onChange={(event) => setLocation('description', event.target.value)}
                    id='description'
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={addLocation}>Add Location</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ToastContainer position='middle-center'>
        <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img src={logo} className='me-2' alt='' width='32px' />
            <strong className='me-auto'>Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {toastContent.content} has been {toastContent.action}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </React.Fragment>
  )
}
