import { Amplify, Auth } from 'aws-amplify'
import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Routes,
  Route,
  Outlet,
  Link,
  useMatch,
  useResolvedPath
} from 'react-router-dom'
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { MDBAlert } from 'mdb-react-ui-kit'
import logo from '../art/bandit-white.svg'
import '@aws-amplify/ui-react/styles.css'

import Dashboard from './Dashboard'
import Alert from './Dashboard/Alert'
import Accounts from './Accounts'
import Account from './Accounts/Account'
import Location from './Accounts/Location'
import Controller from './Accounts/Controller'
import Action from './Accounts/Action'
import Sensor from './Accounts/Sensor'
import Block from './Accounts/Block'
import Schedule from './Accounts/Schedule'
import Camera from './Accounts/Camera'
import Users from './Users'
import User from './Users/User'
import Post from './Post'

import Form from './Form/Form'

import awsExports from '../aws-exports'
Amplify.configure(awsExports)

function App({ signOut, user }) {
  const [admin, setAdmin] = useState(undefined)

  useEffect(() => {
    checkAdmin()
  }, [admin])

  async function checkAdmin() {
    try {
      const { accessToken } = await Auth.currentSession()
      const accesslevel = accessToken.payload['cognito:groups']
      //console.log(accesslevel)
      if (accesslevel.includes('Admins')) {
        setAdmin(true)
      } else {
        setAdmin(false)
      }
    } catch (err) { console.log('error checking user') }
  }

  return (
    <Routes>
    {admin ?
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />
        <Route path="alert/:alertid" element={<Alert />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="account/:accountid" element={<Account />} />
        <Route path="location/:locationid" element={<Location />} />
        <Route path="users" element={<Users />} />
        <Route path="user/:username" element={<User />} />
        <Route path="controller/:controllerid" element={<Controller />} />
        <Route path="action/:actionid" element={<Action />} />
        <Route path="sensor/:sensorid" element={<Sensor />} />
        <Route path="block/:blockid" element={<Block />} />
        <Route path="schedule/:scheduleid" element={<Schedule />} />
        <Route path="camera/:cameraid" element={<Camera />} />
        <Route path="post" element={<Post />} />
        <Route path="form" element={<Form />} />
        <Route path="profile" element={<Profile />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      :
      <Route path="/" element={<Locked />}>
      </Route>
    }
  </Routes>
  );
}

export default withAuthenticator(App);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <React.Fragment>
      <Link className={match && 'active'} to={to} {...props} >
        {children}
      </Link>
    </React.Fragment>
  );
}

function Locked() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <React.Fragment>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>
                <Link to="/">
                  <img src={logo} className="me-2" alt="" width='140px' />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                </Nav>
                <Nav>
                  <Nav.Link>Logged in as {user.username}</Nav.Link>
                  <button className="btn btn-secondary" onClick={signOut}>Sign out</button>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container>
            <h1>Please contact the admin for access.</h1>
          </Container>
        </React.Fragment>
      )}
    </Authenticator>
  );
}

function Layout() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <React.Fragment>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>
                <Link to="/">
                  <img src={logo} className="me-2" alt="" width='140px' />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Item><CustomLink to="/">Dashboard</CustomLink></Nav.Item>
                  <Nav.Item><CustomLink to="/accounts">Accounts</CustomLink></Nav.Item>
                  <Nav.Item><CustomLink to="/users">Users</CustomLink></Nav.Item>
                </Nav>
                <Nav>
                  <Nav.Link>Logged in as {user.username}</Nav.Link>
                  <button className="btn btn-secondary" onClick={signOut}>Sign out</button>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="content">
            <Outlet />
          </div>
        </React.Fragment>
      )}
    </Authenticator>
  );
}

function Profile() {
  return (
    <Container>
      <h2>Profile</h2>
      <hr />
      <p>Coming Soon</p>
    </Container>
  );
}

function NoMatch() {
  return (
    <Container>
      <MDBAlert className='w-100' color='danger'>
        <i class="fas fa-exclamation-triangle fa-4x"></i>
        <h3 className='alert-heading'>Oops!</h3>
        <h5>It's not you, it's us</h5>
        <hr />
        <h4>We can't find any data at this location, here's a button for you to get back... </h4>
        <button type="button" class="btn btn-dark btn-lg"><Link to="/">HOME</Link></button>
      </MDBAlert>
    </Container>
  );
}