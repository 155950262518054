import React, { useState, useEffect } from 'react'
import { Auth, API } from 'aws-amplify'
import Container from 'react-bootstrap/Container'
import { useLocation } from 'react-router-dom'
import { AdminGetUserCommand, AdminListGroupsForUserCommand, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { MDBInputGroup, MDBSelect, MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import Select from 'react-select'
import logo from '../../art/bandit.svg'

var _ = require('lodash')

export default function User() {
  const location = useLocation()
  let username = location.pathname
  username = username.replace('/user/', '')

  const [user, setUser] = useState([])
  const [group, setGroup] = useState('')
  const [toast, setToast] = useState(false)
  const [toastContent, setToastContent] = useState({ action: '', content: '' })

  const groupsops = [
    { label: 'Admins', value: 'Admins' },
    { label: 'Users', value: 'Users' },
  ]

  const config = {
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    region: 'us-west-2',
  }
  const client = new CognitoIdentityProviderClient(config)

  async function onLoad() {
    try {
      const getuser = new AdminGetUserCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
        Username: username,
      })
      const userres = await client.send(getuser)
      setUser(userres)
      const getgroup = new AdminListGroupsForUserCommand({
        UserPoolId: 'us-west-2_XEhEW3MlE',
        Username: username,
      })
      const groupres = await client.send(getgroup)
      console.log(groupres)
      setGroup(groupres.Groups[0].GroupName)
    } catch (error) {
      console.error(error)
    }
  }

  async function addToGroup() {
    let remove = 'Admins'
    if (group == 'Admins') {
      remove = 'Users'
    }
    let add = {
      UserPoolId: 'us-west-2_XEhEW3MlE',
      Username: username,
      GroupName: group,
    }
    let del = {
      UserPoolId: 'us-west-2_XEhEW3MlE',
      Username: username,
      GroupName: remove,
    }
    try {
      const adduser = new AdminAddUserToGroupCommand(add)
      await client.send(adduser)
    } catch (err) {
      console.log(err)
    }
    try {
      const deluser = new AdminRemoveUserFromGroupCommand(del)
      await client.send(deluser)
  } catch (error) {
    console.error(error)
  }
  setToastContent({ action: "'s permissions were updated.", content: username })
  setToast(true)
  }

  function handleGroup(e) {
    if (e) {
      let v = e.value
      setGroup(v)
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <Container>
      <h2>User</h2>
      <hr />
      <MDBRow className='my-3 form-selects'>
        <MDBCol md='6'>
          <h3>{user.Username}</h3>
          <h5>{user.UserStatus}</h5>
        </MDBCol>
        <MDBCol md='6'>
          <MDBInputGroup textBefore='Change Permission Group' className='mb-3 mt-2 form-selects'>
            <Select className='react-select' options={groupsops} placeholder={group ? group : 'None'} onChange={(e) => handleGroup(e)} />
          </MDBInputGroup>
          <MDBBtn onClick={addToGroup}>SAVE</MDBBtn>
        </MDBCol>
      </MDBRow>
      <ToastContainer position='middle-center'>
        <Toast onClose={() => setToast(false)} show={toast} delay={3000} autohide>
          <Toast.Header>
            <img src={logo} className='me-2' alt='' width='32px' />
            <strong className='me-auto'>Bandit Control Panel</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body>
            {toastContent.content}
            {toastContent.action}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  )
}
